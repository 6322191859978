
import { useState } from "react"
import { MdAdd, MdKeyboardArrowRight, MdOutlineDiamond } from "react-icons/md"
import Modal from "react-modal"
import { toast } from "react-toastify"
import CloseButton from "shared/CloseButton"
import NotificationModal from 'shared/modal/NotificationModal'
import iconPanelDelete from 'assets/icons/panel/botao-excluir.svg'
import iconPanelEdit from 'assets/icons/panel/botao-editar.svg'
import useWindowSize from "hooks/useWindowSize"
import { usePermission } from "hooks/usePermission"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from "const"
import { useQuotationPanel } from "../context"
import { ModalTypes } from "../types"

function ModalManagePanels() {

  const { width } = useWindowSize()
  const { state, actions } = useQuotationPanel()
  const [isOpenNotification, setIsOpenNotification] = useState({ status: false })

  const isOpen = state.modal?.type === ModalTypes.MANAGE_PANELS
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH_MOBILE
  const editableLists = (state.lists || []).filter(({ type }) => type === 'QUOTATION_LIST')

  const permission = usePermission('QuotationPanel')
  const hasCreatePanelPermission = permission?.childrens?.some(({ key }) => key === "CreatePanel")

  const selectList = (listId) => {
    actions.selectListById(listId)
    actions.closeModal()
  }

  const removeList = async (list) => {
    try {
      setIsOpenNotification({ status: false })
      await actions.deleteList(list.id)
      toast.success(`O painel ${list.name} foi deletada com sucesso!`)
    } catch (error) {
      toast.error('Não foi possível deletar o painel...')
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => actions.closeModal()}
      style={{
        content: {
          maxWidth: 648,
          margin: "auto",
          marginRight: isMobile ? -40 : "auto",
          marginLeft: isMobile ? -40 : "auto",
        }
      }}>
      <CloseButton onClick={() => actions.closeModal()} />
      <div className="relative flex flex-col h-full">
        <div className="mb-5">
          <h2 className="font-bold text-xl text-primary uppercase mb-2">Gerenciar Painéis</h2>
        </div>
        <div className="p-2">
          {
            editableLists.map(list => (
              <div
                className="flex text-sm border-b last:border-0 items-center space-x-2 py-2"
                key={list.id}>
                <div className="flex-1 text-primary font-semibold space-x-2 flex items-center opacity-90 hover:opacity-100">
                  {list.name}
                </div>
                <div className="flex space-x-5">
                  <MdKeyboardArrowRight
                    className="text-xl cursor-pointer text-primary"
                    title="Visualizar painel"
                    onClick={() => selectList(list.id)}
                  />
                  <button
                    className="text-primary text-xl opacity-90 hover:opacity-100 cursor-pointer"
                    title="Editar painel"
                    onClick={() => actions.openModal(ModalTypes.LIST_DETAILS, { id: list.id })}
                  >
                    <img src={iconPanelEdit} alt="icone de editar" />
                  </button>
                  <button
                    onClick={() => setIsOpenNotification({ status: true, list })}
                    className={`text-loss text-xl opacity-90 cursor-pointer hover:opacity-100`}
                    title="Remover painel"
                  >
                    <img src={iconPanelDelete} alt="icone de excluir" />
                  </button>
                </div>
              </div>
            ))
          }

        </div>

        <div
          onClick={() => actions.openModal(ModalTypes.LIST_DETAILS)}
          className={`flex items-center space-x-2 p-2 text-primary cursor-pointer ${!hasCreatePanelPermission && 'opacity-60'}`}
        >
          <div className="flex justify-center items-center">
            <div className="w-4 h-4 flex justify-center items-center rounded-full font-semibold text-base bg-primary text-secondary">
              <MdAdd style={{ width: '16px', height: '16px' }} />
            </div>
          </div>
          <span className="w-full text-primary font-semibold uppercase">Criar novo painel</span>
          {!hasCreatePanelPermission && <MdOutlineDiamond className="text-base text-terciary ml-2 inline" />}
        </div>

        <div className="flex justify-center space-x-2 mt-3">
          <button className="btn btn-primary" onClick={() => actions.closeModal()}>
            Fechar
          </button>
        </div>
      </div>

      <NotificationModal
        isOpen={isOpenNotification.status}
        closeModal={() => setIsOpenNotification({ status: false })}
        title={`Confirma a exclusão do painel ${isOpenNotification?.list?.name}?`}
        style={{ content: { maxWidth: 460, maxHeight: width < 500 ? 360 : 260, borderRadius: 10, margin: "auto" } }}
        successModal={() => removeList(isOpenNotification.list)}
        actionButtons />
    </Modal >
  )

}

export default ModalManagePanels