import { useCallback, useEffect, useMemo, useState } from "react"
import WidgetContextualMenu from "shared/WidgetContextualMenu"
import QuoteApi from "api/QuoteApi"
import { HelpTooltip } from "shared/HelpTooltip"
import { BtnToggleMyList } from "shared/MyList"
import { abs, format } from "helpers/numberHelper"
import Price from "shared/Price"
import useSymbol from "hooks/useSymbol"
import { Keys, COL_ORDER } from "const"
import SymbolAutocomplete from "shared/SymbolAutocomplete"
import AuctionIndicator from "shared/AuctionIndicator"
import Delay from "shared/Delay"
import { LoadingBox } from "shared/Loading"
import { WidgetTitle } from "shared/WidgetTitle"
import { AnalystsTooltip } from "shared/AnalystsTooltip"
import useWindowSize from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH } from "const"
import DeniedPermissionWidget from "shared/DeniedPermissionWidget"

function QuickQuote({ id, params, ...props }) {

  const [symbol, setSymbol] = useState({ originId: params?.symbol?.originId || 2, symbolCode: params?.symbol?.symbolCode || "PETR4" })

  const handleSetSymbol = useCallback((value) => {
    setSymbol(value)
    if (id && props?.updateWidgetParams) props.updateWidgetParams({ id, params: { symbol: value } })
  }, [setSymbol, id, props])

  return (
    <div className={`bg-white h-full w-full rounded flex flex-col`}>
      <div className={`px-4 ${props?.removeItem ? 'drag-handle pt-4' : ''}`}>
        {props?.removeItem && <WidgetContextualMenu removeItem={() => props.removeItem(id)} />}
        <WidgetTitle title="Cotação Rápida" />
      </div>
      <DeniedPermissionWidget widgetSize={1} widgetType="QuickQuote">
        <div className={`h-full px-4 overflow-auto mini-scrollbar`}>
          <SymbolAutocomplete setSymbol={handleSetSymbol} initialSymbol={symbol?.symbolCode} activeSymbol={true} />
          <div className="mb-1" />
          {!symbol?.symbolCode ? (
            <div className="h-full w-full flex items-center justify-center text-gray-500">
              Nenhum ativo selecionado
            </div>
          ) : (
            <>
              <StockInfo symbol={symbol} />
              <Consensus symbol={symbol} id={id} />
            </>
          )}
        </div>
      </DeniedPermissionWidget>

    </div>
  )
}

function StockInfo({ id, symbol }) {

  const [date, setDate] = useState(null)
  const [marketIndicatorsResponse, setMarketIndicatorsResponse] = useState(null)
  const [item, setItem] = useState({ nome: null, cdec: null, unid: null, tipo: null, gmt: null })
  const [loading, setLoading] = useState(true)
  const data = useSymbol(symbol.symbolCode, symbol.originId)
  const clock = useMemo(() => (<div title="Última atualização do dado recebido" className="w-2/12 flex-none text-base font-semibold text-right">
    <div className="text-gray-500">{date}</div>
  </div>), [date])

  useEffect(() => {
    setDate(null)
    setLoading(true)
    QuoteApi.marketIndicators(
      symbol.originId,
      symbol.symbolCode,
      `${Keys.NOME},${Keys.CDEC},${Keys.UNID},${Keys.TIPO},${Keys.GMT}`
    ).then((res) => { setMarketIndicatorsResponse(res) }).finally(() => setLoading(false))
  }, [symbol])

  useEffect(() => {
    const res = marketIndicatorsResponse
    if (!res) return setItem({ nome: null, cdec: null, unid: null, tipo: null, gmt: null })
    res[0]?.fieldsMap.forEach((item) => {
      if (item.fieldId === Keys.NOME) setItem((e) => ({ ...e, nome: item.value ? item.value : data && data[Keys.NSINAL] }))
      if (item.fieldId === Keys.CDEC) setItem((e) => ({ ...e, cdec: item.value }))
      if (item.fieldId === Keys.UNID) setItem((e) => ({ ...e, unid: item.value }))
      if (item.fieldId === Keys.TIPO) setItem((e) => ({ ...e, tipo: item.value }))
      if (item.fieldId === Keys.GMT) setItem((e) => ({ ...e, gmt: item.value }))
    })
  }, [marketIndicatorsResponse, data])

  useEffect(() => {
    if (!data) return
    const currentDate = new Date()
    if (data[Keys.HULTGMT] && (new Date(data[Keys.HULTGMT]).getDate() === currentDate.getDate())) {
      const options = {
        hourCycle: 'h24',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }
      const lastDate = Date.parse(data[Keys.HULTGMT])
      const formattedDate = new Date(lastDate + (60000 * item.gmt))
      item.gmt && setDate(new Intl.DateTimeFormat('pt-BR', options).format(formattedDate))
    } else {
      if (data[Keys.DULTCOT]) {
        const options = {
          month: '2-digit',
          day: '2-digit'
        }
        const lastDate = Date.parse(data[Keys.DULTCOT])
        setDate(new Intl.DateTimeFormat('pt-BR', options).format(lastDate))
      }
    }
  }, [data])

  return (
    <>
      {loading ? <LoadingBox /> : <>
        <div className="w-full flex justify-between items-center">
          <div className="w-10/12">
            <div className="w-full text-2xl text-primary font-bold flex space-x-2 items-center">
              <h2 id='TooltipQuickQuote' className="uppercase overflow-hidden whitespace-nowrap text-ellipsis">{item?.nome || symbol?.symbolCode}</h2>
              {typeof item?.nome === 'string' && item.nome.replace(/\s/g, '').length > 15 && <HelpTooltip anchor='#TooltipQuickQuote' value={item.nome} />}
              {data && <>
                <Delay item={data} />
                <AuctionIndicator className="w-3 p-[5px] ml-0" item={data} />
              </>}
              <BtnToggleMyList symbol={symbol.symbolCode} origin={symbol.originId} />
            </div>
          </div>
        </div>
        <p className="text-gray-600 mb-2 space-x-1 flex items-center">
          {symbol.origin}
        </p>
        {item && date &&
          <Price
            symbol={symbol.symbolCode}
            origin={symbol.originId}
            props={item}
            containerClass="text-2xl flex flex-wrap gap-2 items-baseline"
            varClass="text-base"
            clock={clock}
          />
        }
      </>}
    </>
  )
}

function Consensus({ id, symbol }) {
  const [response, setResponse] = useState(null)
  const symbolData = useSymbol(symbol.symbolCode, symbol.originId)
  const actualPrice = symbolData && symbolData[Keys.ULTCOT]
  const [prices, setPrices] = useState()
  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH
  id = id.replace(/:/g, "_")

  useEffect(() => {
    QuoteApi.consensusRecomendation({
      Simbolos: symbol.symbolCode,
      Origem: symbol.originId,
    }).then((res) => {
      if (res.media) {
        res.potencialValorizacao = res.media - actualPrice
        res.potencialValorizacaoPercent = ((res.media / actualPrice) - 1) * 100
      }
      setResponse(res)
      const resPrices = [
        { type: "min", price: res.menor, label: "Menor" },
        { type: "actual", price: actualPrice, label: "Atual" },
        { type: "median", price: res.media, label: "Média" },
        { type: "max", price: res.maior, label: "Maior" },
      ]
      const uniquePrices = [...new Set(resPrices.map(item => item.price))]
      uniquePrices.length === 2 ?
        setPrices(resPrices.filter(item => item.type === "actual" || item.type === "median")) :
        setPrices(resPrices.sort((a, b) => (a.price < b.price ? -1 : 1)))
    })
  }, [symbol, actualPrice])

  if (!response?.detalhes || !symbol?.symbolCode) return

  const pricesFormatted = prices.sort((a, b) => (a.price < b.price ? -1 : 1))
  const bigger = prices[prices?.length - 1].price
  const smaller = prices[0].price
  const commonFraction = (bigger - smaller) / 100

  let scale = []
  for (let index = 0; index < 99; index++) {
    scale[index] = smaller + (index * commonFraction)
  }

  scale.push(bigger)

  
  const displayMap = {
    'Compra': { color: 'bg-profit', order: 1 },
    'Neutro': { color: 'bg-gray-300', order: 2 },
    'Venda': { color: 'bg-loss', order: 3 },
    'Em Revisão': { color: 'bg-amber-400', order: 4 },
  }

  const analystsOpinion = response.detalhes?.map((item) => ({
    id: displayMap[item.recomendacao === 'Neutra' ? 'Neutro' : item.recomendacao].order,
    type: item.recomendacao,
    qty: item.analistas,
    color: displayMap[item.recomendacao === 'Neutra' ? 'Neutro' : item.recomendacao].color,
    contributors: item.contribuidores.map((contribuidor, i) => { return i === item.contribuidores?.length - 1 ? `${contribuidor.contribuidor}.` : `${contribuidor.contribuidor}, ` })
  })).sort((a, b) => a.id < b.id ? - 1 : a.id > b.id ? 1 : 0)

  prices.forEach((el, i) => {
    const colNumber = scale.findIndex((e, index) => {
      const next = index + 1 >= scale.length ? scale.length - 1 : index + 1
      if (el.price >= e && el.price <= scale[next]) return e
      return null
    })
    const colIndex = colNumber + 1
    const positionClass = prices.length === 2
      ? (i === 0 ? 'left-3/10' : 'left-6/10')
      : (i === 0 ? 'left-0' : i === pricesFormatted.length - 1 ? 'right-0' : COL_ORDER[colIndex])

    el.itemClass = positionClass
    el.colNumber = colIndex
  })

  for (let i = prices.length - 2; i >= 0; i--) {
    const currentEl = prices[i]
    const nextEl = prices[i + 1]

    if (nextEl.colNumber - currentEl.colNumber < 15) {
      const adjustedColNumber = Math.max(nextEl.colNumber - 15, 1)
      currentEl.colNumber = adjustedColNumber
      currentEl.itemClass = COL_ORDER[adjustedColNumber]
    }
  }

  const getWidth = (n) => (100 / response.recomendacoes) * n + "%"

  return (
    <>
      <hr className="my-[30px] w" />
      <div className="flex justify-between mb-[30px]">
        <h4 className="uppercase font-bold text-primary text-xl">
          Consenso de Analistas
        </h4>
        <span className="text-primary font-semibold space-x-1">
          <span className="text-sm">Metodologia</span>
          <HelpTooltip id={`methodology_method_${id}`} className=" font-semibold text-xs text-center" place="left">
            Consenso de analistas:
            Para ser <br />exibido o gráfico, a ação deve ter <br />alguma
            recomendação disponível <br />na base do Valor PRO.
            <br /><br />
            Preço-alvo médio:
            Considera a média <br /> de projeções para fim do <br /> exercício corrente ou o próximo.
          </HelpTooltip>
        </span>
      </div>

      <div className="flex flex-col gap-[10px] text-sm mb-[30px]">
        <div className="flex items-center space-x-2">
          <h4 className="uppercase text-primary text-base font-semibold">Recomendação</h4>
          <HelpTooltip id={`recomendation_method_${id}`} place={width < 1390 ? "top" : "right"}>
            Distribuição de recomendações <br />de analistas de bancos,
            corretoras <br />e casas de análises fornecidas ao<br /> Valor PRO.
          </HelpTooltip>
        </div>
        <p className="font-semibold text-base text-dark-gray">Analistas: {response.recomendacoes}</p>

        <div className="rounded-lg overflow-hidden flex">
          {
            analystsOpinion.map(({ type, qty, color, contributors }) => {
              const key = `analysts_${color}_${id}`
              return (
                <AnalystsTooltip place="top" id={key} key={key} className={`font-bold text-center ${!isMobile ? 'max-w-[190px]' : 'max-w-[90vw]'}`} analyst={{ type, qty, color, formattedQty: getWidth(qty) }}>
                  {`${type}: `}
                  {contributors.map((contributor, i) => (
                    <span key={i}>
                      {contributor}
                    </span>
                  ))}
                </AnalystsTooltip>
              )
            })
          }
        </div>
        <div className="flex">
          {Object.entries(displayMap).map(([type, { color }]) => (
            <div key={`${id}_label_${type}`} className="flex items-center space-x-2 w-1/4 text-xs">
              <span className={`${color} w-5 h-5 inline-block rounded`}></span>
              <span className="font-semibold text-base text-dark-gray">{type && `${type[0]}${type.toLowerCase().substring(1)}`}</span>
            </div>
          ))}
        </div>
      </div>

      {prices.every((item) => item.price) && (
        <div className="space-y-2 pb-[30px] text-sm">
          <div className="flex items-center space-x-2">
            <h4 className="uppercase text-primary font-semibold text-base">
              Preço alvo (R$)
            </h4>
            <HelpTooltip id={`target_price_${id}`} place={width < 1390 ? "top" : "right"}>
              Cotação atual da ação em <br />comparação com preços-alvo <br />
              projetados pelos analistas que <br /> contribuem para a base do Valor PRO.
            </HelpTooltip>
          </div>
          <div>
            <span className="font-semibold text-base text-dark-gray">Potencial: </span>
            <span className={`font-bold text-base ml-2 ${response.potencialValorizacao > 0 ? "text-profit" : "text-loss"}`}>
              {/* {abs(response.potencialValorizacao, "currency")} ( */}
              {abs(response.potencialValorizacaoPercent, "percent")}
            </span>
          </div>
          <div className="pt-20 relative flex flex-col justify-center">
            <div className="border border-gray-70 absolute w-full"></div>
            {pricesFormatted && pricesFormatted.map(({ price, itemClass, label, type }) => (
              <div key={"priceline-" + type} className={`absolute flex flex-col items-center space-y-1 
              ${type === "actual" && "pointer-events-none z-10 pb-[30px] space-y-0 scale-110 font-bold text-primary top-3"} ${itemClass}`}>
                <div className={`text-base rounded-md hover:z-50 text-center hover:bg-white ${type === 'actual' && 'text-xl'}`}>{format(price, "currency")}</div>
                {type === 'actual' && <span className="h-6 border-dashed border-grayDisabled border"></span>}
                <div className={`${type === "actual" ? "rounded-full bg-primary w-[15px] h-[15px]" : type === 'median' ? 'bg-gray-70 w-[10px] h-[10px]' : "rounded-full bg-gray-70 w-[10px] h-[10px]"}`} />
                {type === 'actual' && <span className="h-6 border-dashed  border-grayDisabled border"></span>}
                <div className="text-base text-dark-gray bg-white font-semibold">{label}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default QuickQuote
