import { useMemo, useContext } from "react"
import { FixedSizeList as List } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"
import Modal from "react-modal"

import { LAYOUT_BREAKPOINT_WIDTH } from "const"
import { OrderByColumnContext, OrderByColumnContextProvider, OrderByColumnDisplay, OrderByColumnTrigger } from "context/OrderByColumnContext"
import { BtnToggleMyList } from "shared/MyList"
import { MdClose } from "react-icons/md"
import { abs, format, formatterBRL } from "helpers/numberHelper"
import useWindowSize from "hooks/useWindowSize"

import { useTraddingSummary } from "../context"

export function DatasetDetailsModal({ close = () => { } }) {

  const { state } = useTraddingSummary()
  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH

  return (
    <Modal
      isOpen={state.datasetModalIsOpen && !!state.selectedDataset}
      style={{
        content:
        {
          maxWidth: isMobile ? width : 648,
          margin: "auto",
          marginRight: isMobile ? -40 : "auto",
          marginLeft: isMobile ? -40 : "auto",
        }
      }} onRequestClose={() => close()}>
      <DatasetDetails openFromModal="true" close={() => close()} />
    </Modal>
  )
}

export function DatasetDetails({ openFromModal = false, close }) {

  const { state } = useTraddingSummary()
  const { type, data = [], label } = state?.selectedDataset || {}

  const cols = useMemo(() => [
    { key: 'symbol', label: 'Código' },
    { key: 'ult', label: 'Último Preço' },
    { key: 'var', label: 'Variação' },
    { key: 'neg', label: 'Número de Negócios' },
  ], [])

  return (
    <OrderByColumnContextProvider data={data} defaultColumn="var" defaultOrder={-1}>
      {
        close && (
          <button
            className="sticky float-right right-2 top-2 text-xl text-primary z-50"
            title="Fechar"
            onClick={() => close()}
          >
            <MdClose />
          </button>
        )
      }
      <div className="h-full flex flex-col">
        <div className={`sticky bg-white ${openFromModal ? '-top-5 pt-4' : 'top-0'}`}>
          {label && <h4 className="font-semibold text-xl mb-2">{label}</h4>}
          <div className="grid grid-cols-4 border-y font-semibold text-xs uppercase">
            {cols.map((col, index) => (
              <OrderByColumnTrigger key={'COL_' + col.key} columnName={col.key}>
                <div className={`border-r first:border-l p-2 flex cursor-pointer ${index === 0 ? 'justify-between' : 'justify-end'} items-center text-right`}>
                  <span>{col.label}</span>
                  <OrderByColumnDisplay columnName={col.key} />
                </div>
              </OrderByColumnTrigger>
            ))}
          </div>
        </div>
        <VirtualizedDatasetDetail type={type} />
      </div>
    </OrderByColumnContextProvider>
  )
}

function VirtualizedDatasetDetail({ type }) {
  const { sortedData } = useContext(OrderByColumnContext)

  if (!sortedData?.length) {
    return <p className="text-gray-500 my-2 text-sm">Nenhum ativo a ser exibido...</p>
  }

  return (
    <div className="flex-1">
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={height}
          width={width}
          itemCount={sortedData.length} // Total items
          itemSize={53} // Approximate height of each row
        >
          {({ index, style }) => (
            <div style={style}>
              <DatasetDetailItem key={`list-${type}-${sortedData[index].id}-${sortedData[index].symbol}`} item={sortedData[index]} type={type} />
            </div>
          )}
        </List>
      )}
    </AutoSizer>
    </div>
  )
}


function DatasetDetailItem({ item }) {
  const color = item.var === 0 ? "dark-gray" : item.var > 0 ? "text-profit" : "text-loss"
  return (
    <div className="border border-t-0 border-gray-200 p-2 hover:bg-gray-100 items-center grid grid-cols-4 text-sm">
      <div className="flex space-x-2 overflow-hidden whitespace-nowrap text-ellipsis">
        <div className="pr-2">
          <div className="flex space-x-1 items-center">
            <h5 className="font-semibold">{item.symbol}</h5>
            <BtnToggleMyList id={item.id} symbol={item.symbol} origin={item.origin} />
          </div>
          <h6 className="text-xs dark-gray">
            {item.companyName}
          </h6>
        </div>
      </div>

      <div className="font-semibold text-right px-2" title="Última cotação">
        {formatterBRL.format(item.ult)}
      </div>
      <div className={`space-x-1 font-semibold text-right px-2 ${color}`} title="Variação no dia de hoje">
        {typeof item.var === 'number' ? (abs(item.var, "percent")) : ''}
      </div>

      <div className="text-right font-semibold px-2 dark-gray">
        {format(item.neg, 'integer')}
      </div >
    </div>
  )
}
