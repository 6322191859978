const route = window.location.pathname
export const BASE_URL = process.env.REACT_APP_BASE_URL

export const headers = new Headers({ 'Content-Type': 'application/json', 'Current-Route': route })

export const memoizeResolver = (...args) => args.map(JSON.stringify).join('_')

export const SearchParams = (params = {}) => {
  const urlSearchParams = new URLSearchParams()
  for (const key in params)
    urlSearchParams.set(key, params[key])
  return urlSearchParams
}

export const getApiHeaders = () => {
  const token = sessionStorage.getItem('TokenApi')
  const tokenType = sessionStorage.getItem('TokenApiType')

  return new Headers({
    'Content-Type': 'application/json',
    'Authorization': token && `${tokenType} ${token}`,
    'Current-Route': route,
  })
}

export const getToken = () => {
  return sessionStorage.getItem('TokenApi')
}

export const customFetch = async (url, opt) => {
  try {
    const res = await fetch(url, opt)

    if (res.status === 401) {
      const response = await res.clone().json()
      if (response.code === 'VALONE_TOKEN_EXPIRED') {
        if (!window.location.href.includes('sessao')) window.open(`${process.env.REACT_APP_GLOBO_ID_REDIRECT}/sessao/inativa`, '_self')
      }
      throw new Error("Não autorizado.")
    }

    if (!res.ok) {
      throw Error(res.statusText)
    }
    return res
  } catch (error) {
    console.error('Erro na requisição', error.message)
    throw error
  }
}