
import { Link, useNavigate } from "react-router-dom"
import Logo from "assets/logo-valor-one-beta.svg"
import useMenu from "hooks/useMenu"
import { useDynamicWindowSize } from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH } from "const"
import { Fragment, useContext } from "react"
import CompanyAutocomplete from "shared/CompanyAutocomplete"
import AccessInterceptorContext from "context/AccessInterceptorContext"
import { MdOutlineDiamond } from "react-icons/md"

function Header() {

  const { width } = useDynamicWindowSize()

  return (
    <header className="w-full px-5 z-50 flex items-center bg-white shadow-md justify-center h-20 min-h-20">
      <Link to="/workspace" className="min-w-[150px]">
        <img src={Logo} alt="Valor One" className="h-12" />
      </Link>
      <div id="custom_subscribe_button" className="header__subscribe-btn"></div>
      {width >= (LAYOUT_BREAKPOINT_WIDTH - 10) && <MenuNav />}
    </header>
  )
}

function MenuNav() {

  const { menuItems } = useMenu()
  const navigate = useNavigate()
  
  const extraOption = {
    type: 'free_search',
    callback: ({ inputString }) => navigate(`/pesquisa?texto=${inputString}`),
    Component: ({ inputString }) => (<div className="my-2 font-semibold opacity-50 text-sm">Pesquisar resultados para "{inputString}"...</div>),
  }

  return (
    <div className="hidden lg:flex space-x-5 items-center flex-grow justify-end">
      <div className="flex items-center relative w-[40%]">
        <CompanyAutocomplete 
          extraOptions={[extraOption]}
          setCompany={company => navigate(`/pesquisa?empresa=${company.name}`)} 
          customParentClass="flex-grow" 
          customClass="relative bg-gray-200 opacity-70 px-3 rounded-xl h-10" />
      </div>
      <ul className="flex list-none space-x-2">
        {menuItems.map(item => <MenuItem key={'menu-item-' + item.name} item={item} />)}
      </ul>
    </div>
  )
}

function MenuItem({ item }) {

  const navigate = useNavigate()
  const circleColor = item.type === 'regular' ? 'bg-primary text-secondary' : 'text-primary bg-secondary'
  const { setIsAccessBlocked } = useContext(AccessInterceptorContext)

  return (
    <div className="flex flex-col items-center w-[55px] space-y-1 relative menu-item-desktop">
      <div
        className={`
          rounded-full 
          ${circleColor}
          h-[35px]
          w-[35px]
          ${item.restricted ? 'cursor-default' : 'cursor-pointer'}
          transition
          ease-in
          flex
          justify-center
          items-center
          hover:scale-110
        `}
        onClick={() => item.url && navigate(item.url)}>
        <img src={item.ico} alt={item.name} className="w-5 h-5" />
      </div>
      { item.subitems?.length > 0 && (
        <div className="shadow-sm fixed bg-white z-50 py-2 top-12 border-gray-200 border rounded-lg w-[180px] flex menu-item-desktop-options">
          <div className="py-2 space-y-1">
            <p className="text-primary uppercase text-xs font-bold text-center">{item.name}</p>
            <p className="text-gray-500 text-xs text-center">{item.subtitle}</p>
          </div>
          {
            item.subitems?.length > 0 && item.subitems.filter(x => !x.onlyMobile).map((subitem, index) => (
              <Fragment key={`menu-item-block-${item.name}-${subitem.name}`} >
                {(
                  subitem.restricted ? (
                    <div onClick={() => setIsAccessBlocked(true)} className="cursor-pointer">
                      <SubItemLayout subitem={subitem} />
                    </div>
                  ) : (
                    <Link
                      onClick={() => subitem.fn && subitem.fn()}
                      to={subitem.url}
                      target={subitem.target}
                    >
                      <SubItemLayout subitem={subitem} />
                    </Link>
                  )
                )}
              </Fragment>

            ))
          }
        </div>
      )}

      <span className="text-primary uppercase text-2xs font-bold cursor-pointer">
        {item.name}
      </span>
    </div>
  )
}

function SubItemLayout({ subitem }) {

  return (
    <div className={`flex items-center hover:bg-gray-100 px-2 space-x-2 py-2`}>
      <div
        className={`
        rounded-full 
        h-[35px] w-[35px] min-w-[35px]
        transition
        ease-in
        flex
        justify-center
        items-center
        ${subitem.type === 'workspace' ? (subitem.isMain ? 'text-secondary bg-primary' : 'text-primary border-2 border-primary') : 'text-primary bg-secondary'}
      `}>
        {subitem.ico && (subitem.ico.includes('.svg') && <img src={subitem.ico} alt={subitem.name} className="w-4 h-4" />)}
        {subitem.url?.includes('modulo') && <div className="material-icons">{subitem.ico}</div>}
        {subitem.md && <div className="material-icons text-xl">{subitem.md}</div>}
      </div>
      <span className="text-primary uppercase text-2xs font-bold flex-1">{subitem.name}</span>
      {subitem.restricted && <MdOutlineDiamond className="text-terciary inline text-lg w-4 min-w-[1rem]" />}
    </div>
  )
}

export default Header