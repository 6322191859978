import { useContext, useEffect, useState, useRef, useReducer } from "react"
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { toast } from "react-toastify"
import Modal from "react-modal"
import { MdClear, MdOutlineEdit } from 'react-icons/md'
import { FundsContext } from 'context/FundsContext'
import useWindowSize from "hooks/useWindowSize"
import AutoSuggest from 'shared/widgets/Funds/components/AutoSuggest'
import CloseButton from "shared/CloseButton"
import { LoadingBox } from "shared/Loading"
import iconPanelDelete from 'assets/icons/panel/botao-excluir.svg'
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from 'const'


export function ModalEditFunds({ isOpen = true, closeModal }) {
  const { stateFunds, dispatchFunds, listEditing, formattedListEditing, updateListPanel, setSelectedCategory } = useContext(FundsContext)
  const initialState = {
    listEdit: {funds: []},
    loading: true,
    disabled: true,
    nameList: ''
  }
  const reducerAction = (state, action) => {
    const states = {
      'SET_LIST': { ...state, listEdit: action.payload },
      'ADD_FUND': { ...state, listEdit: {...state.listEdit, funds: [...state.listEdit.funds.filter((fund) => action.payload?.cnpjNumberClean !== fund.cnpjNumberClean), action.payload]} },
      'DELETE_FUND': { ...state, listEdit: {...state.listEdit, funds: [...state.listEdit.funds.filter((e) => action.payload?.cnpjNumberClean !== e.cnpjNumberClean)]} },
      'SET_NAME': { ...state, nameList: action.payload },
      'SET_LOADING': { ...state, loading: action.payload },
      'SET_DISABLED': { ...state, disabled: !state.disabled }
    }
    return states[action.type]
  }
  const [state, dispatch] = useReducer(reducerAction, initialState)
  
  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH_MOBILE

  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: 'SET_LOADING', payload: false })
    }, 500)

    if (listEditing) {
      dispatch({ type: 'SET_LIST', payload: listEditing })
      dispatch({ type: 'SET_NAME', payload: listEditing.panelName })
      return
    } else if (stateFunds.listActive) {
      dispatch({ type: 'SET_LIST', payload: stateFunds.listActive })
      dispatch({ type: 'SET_NAME', payload: stateFunds.listActive.panelName })
      return
    }
  }, [listEditing, stateFunds.listActive])
  
  const save = async () => {
    try {
      if (!state.nameList) return
      await updateListPanel({ Id: state.listEdit.id, Name: state.nameList, CNPJNumbers: [...state.listEdit.funds.map(({ cnpjNumberClean }) => cnpjNumberClean)] })
      setSelectedCategory(stateFunds.listActive)
      dispatchFunds({ type: 'SET_LIST_ACTIVE', payload: {...state.listEdit, panelName: state.nameList} })
      toast.success(`A lista ${state.nameList} foi editada com sucesso!`)
      closeModalEdit()
    } catch (error) {
      toast.error('Não foi possível editar a lista')
      closeModalEdit()
    }
  }

  const updateList = (fund) => {
    if (fund) dispatch({ type: 'ADD_FUND', payload: fund })
  }

  const closeModalEdit = () => {
    formattedListEditing(null)
    closeModal()
  }

  return (
    <DndProvider backend={HTML5Backend} >
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={
          {
            content:
            { 
              maxWidth: 602,
              borderRadius: 12,
              margin: "auto",
              marginRight: isMobile ? -40 : "auto",
              marginLeft: isMobile ? -40 : "auto"
            }
          }
        }
      >
        <CloseButton className='absolute right-5 top-5 z-50' size={20} color={'#472F92'} onClick={closeModalEdit} />
        <div className="relative flex flex-col h-full">
          <div className="mb-5">
            <h2 className="font-bold text-xl text-primary uppercase mb-2">Editar lista</h2>
          </div>
          <div className="relative mb-3">
            <input
              onChange={(e) => dispatch({ type: 'SET_NAME', payload: e.target.value.toUpperCase() })}
              value={state.nameList}
              type="text"
              className={`input mb-0 px-[30px] py-4 rounded-lg text-dark-gray ${state.disabled ? 'cursor-not-allowed' : ''} ${state.nameList?.length === 0 && 'border-2 border-legend-loss'}`}
              maxLength={20}
              placeholder="Digite aqui o nome (máx. 20 caracteres)"
              autoFocus
              disabled={state.disabled}
            />
            {state.disabled 
              ? <button className="absolute right-3 top-5 text-primary" onClick={() => dispatch({ type: 'SET_DISABLED' })}><MdOutlineEdit /></button>
              : state.nameList.length > 0 && <button className="absolute right-3 top-5 text-primary" onClick={() => dispatch({ type: 'SET_NAME', payload: '' })}><MdClear /></button>
            }
            <div className="flex justify-between">
              <span className='flex justify-start text-xs text-legend-loss'>{state?.nameList?.length === 0 ? 'Preencha o nome' : ''}</span>
              <span className='flex justify-end text-xs text-dark-gray'>{`${state?.nameList?.length}/20`}</span>
            </div>
          </div>
          <div className="w-full">
            <AutoSuggest setFund={(fund) => updateList(fund) } labelPlaceholder='Digite o nome ou CNPJ do fundo para adicionar' width="100%" />
          </div>
          {state.loading
            ? <div className='grow flex justify-center h-80'>
              <LoadingBox />
            </div>
            : <CardList listEdit={state.listEdit} addFund={(fund) => dispatch({ type: 'ADD_FUND', payload: fund })} deleteFund={(fund) => dispatch({ type: 'DELETE_FUND', payload: fund }) } />}
          <div className="flex justify-center space-x-3 mt-3">
            <button className="btn btn-secondary" onClick={closeModalEdit}>Cancelar</button>
            <button className="btn btn-primary text-secondary" onClick={save}>Salvar</button>
          </div>
        </div>
      </Modal >
    </DndProvider>
  )
}

function CardList({ listEdit, addFund, deleteFund }) {
  const [cardSelected, setCardSelected] = useState(null)

  function moveFundsEditList(from, to) {
    const dragged = listEdit.funds[from]
    setCardSelected(dragged)
    const newList = {...listEdit.funds}
    newList.splice(from, 1)
    newList.splice(to, 0, dragged)
    addFund(newList)
  }

  return (
    <ul className={`${(!listEdit.funds || listEdit.funds?.length === 0) && 'flex-1 border border-[#DBDBDB] rounded-lg'} mt-3 w-full overflow-auto mini-scrollbar grow flex flex-col`}>
      <div className={`${ listEdit.funds?.length && "border border-b-0 border-[#DBDBDB]" }`}>
        {listEdit.funds?.length
          ? listEdit.funds.map((fund, index) => (
            <Card
              key={fund?.cnpjNumberClean}
              data={fund}
              index={index}
              moveCard={moveFundsEditList}
              updatedCardSelected={setCardSelected}
              deleteFund={deleteFund}
              classContainer={`py-1 ${!cardSelected && 'hover:bg-[#EDEDED]'}`}
            />
          ))
          : <div className="grow flex items-baseline">
            <span className="mt-[30px] ml-[30px] text-grayDisabled">Nenhum fundo selecionado.</span>
        </div>}
      </div>
    </ul>
  )
}

function Card({ data, index, moveCard, updatedCardSelected, classContainer, deleteFund }) {
  const ref = useRef()
  const { width } = useWindowSize()
  // const [{ content }, dragRef] = useDrag({
  //   type: 'CARD',
  //   item: { index, data },
  //   collect: (monitor) => ({
  //     content: monitor.getItem()
  //   })
  // })

  // const [, dropRef] = useDrop({
  //   accept: 'CARD',
  //   hover: (item, monitor) => {
  //     const dragIndex = item.index
  //     const targetIndex = index
  //     if (dragIndex === targetIndex) return

  //     const targetSize = ref.current.getBoundingClientRect()
  //     const targetCenter = (targetSize.bottom - targetSize.top) / 2

  //     const draggedOffset = monitor.getClientOffset()
  //     const draggedTop = draggedOffset.y - targetSize.top

  //     if (dragIndex < targetIndex && draggedTop < targetCenter) return
  //     if (dragIndex > targetIndex && draggedTop > targetCenter) return

  //     moveCard(dragIndex, targetIndex)
  //     item.index = targetIndex
  //   },
  //   drop: (item, monitor) => {
  //     if (monitor.isOver()) updatedCardSelected(null)
  //   }
  // })

  // dragRef(dropRef(ref))

  return (
    <div ref={ref} className={`w-full border-b border-[#DBDBDB] ${width < 400 && 'w-11/12'} ${classContainer}`}>
      <div className={`flex justify-between items-center space-x-2 px-[10px] text-dark-gray`}>
        {/* <MdDragIndicator className='flex-none cursor-grabbing' /> */}
        <div className={`w-11/12 flex flex-col`}>
          <span className='w-full font-semibold line-clamp-2'>{data.foundName}</span>
          <span className='text-sm'>{data.cnpjNumber}</span>
        </div>
        <button
          onClick={() => deleteFund(data)}
          className={`flex-none text-loss text-xl opacity-90 cursor-pointer hover:opacity-100`}
          title="Remover fundo"
        >
          <img src={iconPanelDelete} alt="icone de excluir" />
        </button>
      </div>
    </div>
  )
}