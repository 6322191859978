import { useMemo } from "react"
import { XAxis, XYPlot, YAxis, VerticalBarSeries, LabelSeries, VerticalGridLines } from "react-vis"
import AutoSizer from "react-virtualized-auto-sizer"

import { LAYOUT_BREAKPOINT_WIDTH_TABLET } from "const"
import { IbovInfo } from "shared/IbovInfo"
import { WidgetTitle, WidgetLinkTitle } from "shared/WidgetTitle"
import { useLayoutEngineContext } from "context/LayoutEngineContext"
import WidgetContextualMenu from "shared/WidgetContextualMenu"
import DeniedPermissionWidget from "shared/DeniedPermissionWidget"
import useMaxWindowSize from "hooks/useMaxWindowSize"
import useTitle from "hooks/useTitle"

import { TraddingSummaryProvider, useTraddingSummary } from "./context"
import useFetchAssetQuotationData from "./hooks/useFetchAssetQuotationData"
import getUpdatedDataset from "./functions/getUpdatedDataset"

import { DatasetDetails, DatasetDetailsModal } from "./components/DatasetDetails"

export function TradingSummaryPage() {
  useTitle('Resumo da Bolsa')
  const isMobile = useMaxWindowSize(LAYOUT_BREAKPOINT_WIDTH_TABLET)
  return (
    <div className="bg-white min-h-full rounded flex flex-col">
      <div className="pt-4 px-4">
        <WidgetTitle title="Resumo da Bolsa" />
      </div>
      <TraddingSummaryProvider colSize={isMobile ? 1 : 3} isMobile={isMobile}>
        <TradingSummary />
      </TraddingSummaryProvider>
    </div>
  )
}

export function TradingSummaryWidget({ id, ...props }) {

  const { currentBreakpoint } = useLayoutEngineContext()
  const widgetSize = props[currentBreakpoint].w || 1

  const options = useMemo(() => currentBreakpoint === 'mobile' ? [] : [
    { label: "Modo Compacto", onClick: () => props.resizeItem({ id, [currentBreakpoint]: { w: 1 } }) },
    { label: "Modo Expandido", onClick: () => props.resizeItem({ id, [currentBreakpoint]: { w: 2 } }) },
  ], [id, props, currentBreakpoint])

  return (
    <div className="bg-white min-h-full rounded flex flex-col">
      <div className="drag-handle pt-4 px-4">
        <WidgetContextualMenu options={options} removeItem={() => props.removeItem(id)} />
        <WidgetLinkTitle route="/resumo-da-bolsa" title="Resumo da Bolsa" />
      </div>

      <DeniedPermissionWidget widgetSize={widgetSize} widgetType="TradingSummary">
        <TraddingSummaryProvider colSize={widgetSize} isMobile={currentBreakpoint === 'mobile'}>
          <TradingSummary />
        </TraddingSummaryProvider>
      </DeniedPermissionWidget>
    </div>
  )
}

function TradingSummary() {

  const { state, actions, isMobile, colSize } = useTraddingSummary()
  useFetchAssetQuotationData()
  const chartMargin = { left: 55, bottom: 45, top: 30 }

  const selectDataset = (key) => {
    const dataset = getUpdatedDataset(key, state.data)
    const datasetModalIsOpen = !!dataset && (isMobile || colSize === 1)
    actions.selectDataset({ dataset, datasetModalIsOpen })
  }

  return (
    <div className="px-4 pb-4 h-full flex-1 flex flex-col">
      <span className="text-gray-500 relative bottom-2 text-sm md:text-base">
        Clique no título, nas barras ou nas legendas para ver detalhes do grupo de ativos.
      </span>
      <div
        className="grid max-h-full flex-1"
        style={{ gridTemplateColumns: `repeat(${colSize}, minmax(0, 1fr))` }}
      >
        <div
          style={{ gridColumn: `span ${Math.max(colSize - 1, 1)} / span ${Math.max(colSize - 1, 1)}`, }}
          className="mb-4 relative"
        >
          <div className="h-full flex flex-col">
            <div className="cursor-pointer hover:opacity-80" onClick={() => selectDataset('ALL')}>
              <IbovInfo />
            </div>
            {state.data?.groups && (
              <div className="flex-1">
                <AutoSizer>
                  {({ width, height }) => (
                    <XYPlot
                      xType="ordinal"
                      margin={chartMargin}
                      width={width || 300}
                      height={(height || 300)}
                      xDistance={100}>
                      <VerticalGridLines style={{ strokeDasharray: 4, strokeWidth: 2 }} />
                      {state.data.groups.map(datagroup => (
                        <LabelSeries
                          key={`LabelSeries_${datagroup.legend}`}
                          getLabel={d => d.y}
                          data={datagroup.data.map(({ x, y }) => ({ x, y }))}
                          labelAnchorY="top"
                          labelAnchorX="middle"
                          className="font-bold text-sm z-50"
                          marginTop={chartMargin.top - 10} />
                      ))}
                      <XAxis />
                      <YAxis hideLine />
                      {
                        state.data.groups
                          .reduce((ac, { data, color }) => [...ac, ...data.map(x => ({ ...x, color }))], [])
                          .map(seriesData => (
                            <VerticalBarSeries
                              key={'VerticalBarSeries_' + seriesData.x}
                              data={[{ x: seriesData.x, y: seriesData.y }]}
                              color={seriesData.color}
                              cluster="main"
                              className={`cursor-pointer hover:brightness-75 ${state.selectedDataset?.key === seriesData.x && 'brightness-50'}`}
                              onValueClick={() => selectDataset(seriesData.key)}
                            />
                          )
                          )
                      }
                    </XYPlot>

                  )}
                </AutoSizer>
              </div>
            )}
            <div className="font-bold text-xs text-center -mt-4">Variação em %</div>
            <div className="font-bold text-xs absolute top-[calc(50%-20px)] -left-14 -rotate-90">Quantidade de ativos</div>
            <div className={`flex mb-1 mt-3 ${!isMobile ? 'space-x-4 justify-center mb-3 flex-wrap' : 'flex-col items-start'}`}>
              {state.data.groups.map(group => (
                <div
                  key={`legend_${group.legend}`}
                  className={`cursor-pointer flex items-center border-b-[3px] whitespace-nowrap ${state.selectedDataset?.key === group.key ? 'border-[#C5C5C5]' : 'border-transparent'}`}
                  onClick={() => selectDataset(group.key)}
                >
                  <div className={`h-4 w-4 mr-2 ${group.colorClassName}`} />
                  <div>{group.legend}</div>
                </div>
              ))}
            </div>
            <p className="dark-gray text-xs text-center">
              Atualizado às {state.lastUpdateTime?.toLocaleString('PT-br').slice(-8)}
            </p>
          </div>
        </div>
        {
          !isMobile && colSize > 1 ? (
            <div
              style={{
                gridColumn: `span ${Math.min(colSize - 1, 1)} / span ${Math.min(colSize - 1, 1)}`,
              }}
              className="h-full"
            >
              <DatasetDetails />
            </div>
          ) : (
            <DatasetDetailsModal close={() => actions.closeDetailsModal()} />
          )
        }
      </div>
    </div>
  )

}
