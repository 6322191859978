import { cloneDeep } from "lodash"
import { ActionTypes } from "./types"
import PreferencesApi from "api/PreferencesApi"

const actions = (dispatch) => {

  return {
    updateFetchResults: ({ data, loading, error }) => {
      dispatch({
        type: ActionTypes.UPDATE_FETCH_RESULTS,
        payload: { data, loading, error }
      })
    },
    setTableTab: (tableTab) => {
      dispatch({
        type: ActionTypes.SET_TABLE_TAB,
        payload: tableTab
      })
    },
    setView: (view) => {
      dispatch({
        type: ActionTypes.SET_VIEW,
        payload: view.name
      })
    },
    selectListById: (id) => {
      dispatch({
        type: ActionTypes.SELECT_LIST_BY_ID,
        payload: id
      })
    },
    openModal: (modalType, modalParams) => {
      dispatch({
        type: ActionTypes.OPEN_MODAL,
        payload: { type: modalType, params: modalParams }
      })
    },
    closeModal: () => {
      dispatch({ 
        type: ActionTypes.CLOSE_MODAL
      })
    },
    createList: async (data) => {
      const results = await PreferencesApi.createPanelQuotationList([data])
      if (!results[0]) {
        throw new Error('Missing response')
      }
      dispatch({
        type: ActionTypes.CREATE_LIST,
        payload: results[0]
      })
    },
    updateList: async (list, toUpdateProps) => {
      list = cloneDeep(list)
      for (const key in list) {
        if (toUpdateProps && toUpdateProps[key])
          list[key] = toUpdateProps[key]
      }
      await PreferencesApi.updatePanelQuotationList(list)
      dispatch({
        type: ActionTypes.UPDATE_LIST,
        payload: list
      })
    },
    deleteList: async (listId) => {
      await PreferencesApi.deletePanelQuotationList(listId)
      dispatch({
        type: ActionTypes.DELETE_LIST,
        payload: listId
      })
    },
    updateListOrder: async (list, symbols) => {
      list = cloneDeep(list)
      list.symbols = symbols
      await PreferencesApi.updatePanelQuotationList(list)
      dispatch({
        type: ActionTypes.UPDATE_LIST,
        payload: list
      })
    },
    removeSymbol: async (list, { symbol, origin }) => {
      const index = list.symbols.findIndex(s => symbol === s.symbol && origin === s.origin)
      if (index === -1) {
        throw new Error(`Symbol ${symbol} not found in list ${list.name}!`)
      }
      list.symbols.splice(index, 1)
      await PreferencesApi.updatePanelQuotationList(list)
      dispatch({
        type: ActionTypes.UPDATE_LIST,
        payload: list
      })
    },
    updateExternalData: (externalData) => {
      dispatch({
        type: ActionTypes.UPDATE_EXTERNAL_DATA,
        payload: externalData 
      })
    },
    updateFavoriteList: (interestList) => {
      dispatch({
        type: ActionTypes.UPDATE_INTEREST_LIST,
        payload: interestList 
      })
    }
  }
}

export default actions