function getDataGroup(data) {
  const getInterval = (min, max, key) => {
    let filteredData = data.filter(x => x.neg > 0)
    if (min) filteredData = filteredData.filter((x) => x.var >= min)
    if (max) filteredData = filteredData.filter((x) => x.var <= max)
    return {
      data: filteredData,
      y: filteredData.length,
      x: key,
      key
    }
  }
  const getGroupInfo = ({ key, data = [] }) => {
    const totalLength = data.reduce((ac, x) => x.data.length + ac, 0)
    return { data, length: totalLength, legend: `${totalLength} ${key}`, fullLegend: `${totalLength} ativos ${key}`, key }
  }

  const unnegociatedData = data.filter(({ neg }) => neg === 0)
  const dataGroups = [
    {
      ...getGroupInfo({
        key: 'em baixa',
        data: [
          { label: "abaixo de -2,50%", ...getInterval(null, -2.51, '< -2.50%') },
          { label: "de -2,50% a -1,51%", ...getInterval(-2.5, -1.51, '-2%') },
          { label: "de -1,50% a -0,51%", ...getInterval(-1.5, -0.51, '-1%') },
        ]
      }),
      color: "#FE260E",
      colorClassName: "bg-legend-loss"
    },
    {
      ...getGroupInfo({
        key: 'estáveis',
        data: [{ label: "de -0,50% a 0,50%", ...getInterval(-0.5, 0.5, '0%') }]
      }),
      color: "#707070",
      colorClassName: "bg-gray-70"
    },
    {
      ...getGroupInfo({
        key: 'em alta',
        data: [
          { label: "de 0,51% a 1,50%", ...getInterval(0.51, 1.5, '1%') },
          { label: "de 1,51% a 2,50%", ...getInterval(1.51, 2.5, '2%') },
          { label: "acima de 2,50%", ...getInterval(2.51, null, '2,50% >') },
        ]
      }),
      color: "#65A503",
      colorClassName: "bg-profit"
    }
  ]

  if (unnegociatedData.length) {
    dataGroups.push({
      ...getGroupInfo({
        key: 'não negociados',
        data: [{ x: "", label: "não negociados", key: 'não negociados', ...(data => ({ y: data.length, data }))(unnegociatedData) }]
      }),
      color: "#00AEEF",
      colorClassName: "bg-legend-unnegociated"
    })
  }

  return dataGroups
}

export default getDataGroup