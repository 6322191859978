import { createContext, useContext, useMemo, useReducer } from "react"
import reducer from "./reducer"
import actionsFactory from "./actions"

const initialState = {
  data: {
    original: [],
    groups: [],
  },
  error: null,
  loading: true,
  selectedDataset: null,
  datasetModalIsOpen: false
}

export const TraddingSummaryContext = createContext(initialState)


export function TraddingSummaryProvider({ children, isMobile, colSize }) {

  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = useMemo(() => actionsFactory(dispatch), [dispatch])

  return (
    <TraddingSummaryContext.Provider value={{ state, actions, isMobile, colSize }}>
      {children}
    </TraddingSummaryContext.Provider>
  )
}

export const useTraddingSummary = () => {
  const context = useContext(TraddingSummaryContext)
  if (!context) {
    throw new Error("useTraddingSummary must be used within a TraddingSummaryProvider")
  }
  return context
}