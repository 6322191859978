import { useEffect, useContext, useState } from "react"
import { TradingView } from "charting_library/charting_library.standalone"
import WidgetContextualMenu from "shared/WidgetContextualMenu"
import AuthContext from 'context/AuthContext'
import { Datafeed, actionDataPersistence } from "./components/datafeed"
import { bodyOverrides } from "./components/helpers"
import { WidgetTitle, WidgetLinkTitle } from "shared/WidgetTitle"
import useTitle from "hooks/useTitle"
import DeniedPermissionWidget from "shared/DeniedPermissionWidget"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from "const"
import useMaxWindowSize from "hooks/useMaxWindowSize"

const TradingViewChart = () => {

  const dataChartDefault = {
    symbol: "Bovespa:PETR4",
    interval: "1D",
  }
  let data
  const dataStorage = window.localStorage.getItem('dataSymbol')
  if (dataStorage) data = JSON.parse(dataStorage) 
  const intervalStorage = window.localStorage.getItem('tradingview.chart.lastUsedTimeBasedResolution')

  const { userInfo } = useContext(AuthContext)
  const [dataPersistence, setDataPersistence] = useState(data ? data : dataChartDefault)
  //const [timeFrameActive, setTimeFrameActive] = useState(null)

  useEffect(() => {
    if (data && Object.keys(data).length === 0) {
      window.localStorage.setItem('dataSymbol', JSON.stringify(dataChartDefault))
    } else {
      window.localStorage.setItem('dataSymbol', JSON.stringify(dataPersistence))
    }
  }, [dataPersistence])

  useEffect(() => {
    const script = document.createElement("script")
    script.type = "text/jsx"
    script.src = "%PUBLIC_URL%/charting_library/charting_library.js"

    document.head.appendChild(script)
    window.planActive = userInfo.signature
    window.tvWidget = new TradingView.widget({
      //debug: true,
      symbol: dataPersistence.symbol,
      interval: intervalStorage || dataChartDefault.interval,
      container: "tv_chart_container",
      datafeed: Datafeed(),
      client_id: 'https://www.valorone.com.br',
      user_id: userInfo.email,
      auto_save_delay: 2,
      load_last_chart: true,
      autosize: true,
      library_path: "/charting_library/",
      locale: "pt",
      timezone: "America/Sao_Paulo",
      loading_screen: { backgroundColor: "#ffffff" },
      settings_overrides: bodyOverrides,
      numeric_formatting: { decimal_sign: "," },
      time_scale: {
        min_bar_spacing: 4,
      },
      time_frames: [
        { text: "1D", resolution: "1", title: "1D" },
        { text: "7D", resolution: "15", title: "7D" },
        { text: "1m", resolution: "D", title: "1M" },
        { text: "6M", resolution: "D", title: "6M" },
        //{ text: "100t", resolution: "D", title: "YTD", description: "ano em dias" },
        { text: "1Y", resolution: "W", description: "1 semana", title: "1A" },
        { text: "5y", resolution: "M", description: "1 mês", title: "5A" },
      ],
      disabled_features: [
        'header_settings',
        'timezone_menu',
        'time_frames'
      ],
      enabled_features: [
        'adaptive_logo',
        'display_data_mode',
        'hide_image_invalid_symbol'
      ]
    })

    window.tvWidget.onChartReady(function () {
      if (!intervalStorage) window.tvWidget.setTimeFrame({
        val: { type: 'period-back', value: '1M' },
        res: '1D',
      })
      //window.tvWidget.subscribe('chart_bottom toolbar', (e) => {
        // window.tvWidget.activeChart().onIntervalChanged().subscribe(null, (interval, timeframeObj) => {
        //   setTimeFrameActive({ interval, timeframe: timeframeObj.timeframe['value'] })
          // if (interval == '1D' && timeframeObj.timeframe['value'] == '1T' ) {
          //   //window.tvWidget.activeChart().resetData()
          //   // const dateNow = Math.floor(Date.now() / 1000)
          //   // const dateOld = new Date(new Date().getFullYear(), 0, 1).getTime() / 1000
          //   // window.tvWidget.chart().createStudy("Volume", false, false, [dateOld, dateNow], {});
          //   // window.tvWidget.activeChart().onDataLoaded.subscribe(null, function() {
          //   // }, true)
          //   //window.tvWidget.activeChart().setVisibleRange({ from: dateOld, to: dateNow }, { applyDefaultRightMargin: 20,  }).then(() => console.log(`Range year: ${dateOld}`));
          // }
        //})
      //})
      
    })

    // window.tvWidget.subscribe('chart_bottom toolbar', (e) => {
    //   const chart = window.tvWidget.chart();
    //   const currentDate = Math.floor(Date.now()) // em segundos
    //   const startOfYear = new Date(new Date().getFullYear(), 0, 1).getTime() // início do ano
    //   if (window.tvWidget.activeChart().resolution() === '1D') {
    //     chart.setVisibleRange({
    //       from: startOfYear, //Date.UTC(2018, 7, 1) / 1000,
    //       to: currentDate //Date.UTC(2020, 1, 1) / 1000
    //     })
    //   }
    // })

    window.tvWidget.subscribe('onAutoSaveNeeded', () => {
      actionDataPersistence(setDataPersistence)
    })

    return () => script.remove()
  }, [])

  return <div className="w-full h-full" id="tv_chart_container" />
}

export function GraphicsWidget({ id, ...props }) {

  const isMobile = useMaxWindowSize(LAYOUT_BREAKPOINT_WIDTH_MOBILE)
  const widgetSize = props[isMobile ? 'mobile' : 'desktop'].w

  return (
    <div className="bg-white h-full rounded flex flex-col">
      <div className="drag-handle pt-4 px-4">
        <WidgetContextualMenu removeItem={() => props.removeItem(id)} />
        <WidgetLinkTitle title="Análise Gráfica" route="/analise-grafica" />
      </div>
      <DeniedPermissionWidget widgetSize={widgetSize} widgetType="Graphics">
        <div className="flex-grow">
          <TradingViewChart />
        </div>
      </DeniedPermissionWidget>
    </div>
  )
}

export function GraphicsPage() {
  useTitle('Análise Gráfica')

  return (
    <div className="bg-white h-full rounded flex flex-col">
      <div className="pt-4 px-4">
        <WidgetTitle title="Análise Gráfica" />
      </div>
      <div className="flex-grow">
        <TradingViewChart />
      </div>
    </div>
  )
}

