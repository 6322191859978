import { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import usePanelQuotationLists from "hooks/fetch/usePanelQuotationLists"

import actionsFactory from "./actions"
import reducer from "./reducer"
import { TableTabTypes, ViewTypes } from "./types"

import ModalAddItems from "./modals/ModalAddItems"
import ModalListDetails from "./modals/ModalListDetails"
import ModalListsManagement from "./modals/ModalManagePanels"
import ModalPanelGuideSubtitles from "./modals/ModalPanelGuideSubtitles"

const initialState = {
  lists: null,
  activeList: null,
  view: ViewTypes.CARD,
  tableTab: TableTabTypes.MAIN,
  loading: false,
  fetchError: null,
  widgetProps: null,
  widgetSize: 1,
  modal: null,
  sortBy: null,
  externalData: {}
}

export const QuotationPanelContext = createContext(initialState)

export function QuotationPanelProvider({ children, widgetProps, widgetSize }) {

  const fetchResult = usePanelQuotationLists()
  const initialStateWithWidgetsProps = useMemo(() => ({
    ...initialState,
    view: widgetProps.params.view || ViewTypes.CARD,
    tableTab: widgetProps.params.tableTab,
    sortBy: widgetProps.params.sortBy,
    widgetProps,
    widgetSize
  }), [widgetProps, widgetSize])

  const [state, dispatch] = useReducer(reducer, initialStateWithWidgetsProps)
  const actions = useMemo(() => actionsFactory(dispatch), [dispatch])
  state.activeList?.symbols?.forEach(s => s.id = `${s.symbol}_${s.origin}`)

  useEffect(() => {
    actions.updateFetchResults(fetchResult)
  }, [fetchResult, actions])

  return (
    <QuotationPanelContext.Provider value={{ state, actions, widgetSize }}>
      {children}
      <ModalAddItems />
      <ModalListDetails />
      <ModalListsManagement />
      <ModalPanelGuideSubtitles />
    </QuotationPanelContext.Provider>
  )
}

export const useQuotationPanel = () => {
  const context = useContext(QuotationPanelContext)
  if (!context) {
    throw new Error("useQuotationPanel must be used within a QuotationPanelProvider")
  }
  return context
}
