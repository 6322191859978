import { useMemo } from "react"
import { TableTabTypes as TableTab, KeysFinancial as Fin, ExternalAPI as API } from "../types"
import { Keys } from "const"

function useTableColumns() {
  return useMemo(() => {

    const tableColumns = {
      [TableTab.MAIN]: {
        dependencies: [API.COMPANY],
        columns: [
          { header: "Ativo", accessor: "symbol", type: "name" },
          { header: "Cotação", accessor: Keys.ULTCOT, type: "cdec" },
          { header: "Var. Dia", accessor: Keys.VAR, type: "varPercent" },
          { header: "Var. Dia (pts)", accessor: Keys.DIFFEC, type: "cdec" },
          { header: "Min.", accessor: Keys.MIN, type: "cdec" },
          { header: "Max.", accessor: Keys.MAX, type: "cdec" },
          { header: "Compra", accessor: Keys.OCP, type: "cdec" },
          { header: "Venda", accessor: Keys.OVD, type: "cdec" },
          { header: "Negócios", accessor: Keys.NEG, type: "integer" },
          { header: "Volume Financeiro", accessor: Keys.VOLF, type: "bigNumber" },
          { header: "Var. Ano", accessor: Keys.VARANO, type: "varPercent" },
          { header: "Var. 12 meses", accessor: Keys.VAR1ANO, type: "varPercent" },
        ]
      },
      [TableTab.VALUATION]: {
        dependencies: [API.COMPANY, API.VALUATION, API.CONSENSUS, API.PROFITABILITY],
        columns: [
          { header: "Ativo", accessor: "symbol", type: "name" },
          { header: "Empresa", accessor: `${API.VALUATION}.nomeFantasia`, type: "companyName" },
          { header: "Preço/Lucro", accessor: Keys.PLC, fallback: Keys.PL, type: "var" },
          { header: "Lucro por Ação", accessor: `${API.PROFITABILITY}.${Fin.LPA}`, type: "float" },
          { header: "Valor de Mercado", accessor: Keys.MKTCAP, type: "bigNumber" },
          { header: "Retorno com Dividendos", accessor: Keys.DIVYLD, type: "varPercent" },
          { header: "EV/EBITDA", accessor: Keys.EVEBTDC, fallback: Keys.EVEBTD, type: "float" },
          { header: "Preço/Valor Patrimonial", accessor: Keys.PVPAC, fallback: Keys.PVPA, type: "cdec" },
          { header: "Preço Alvo", accessor: `${API.CONSENSUS}.media`, type: "cdec" },
        ]
      },
      [TableTab.PROFITABILITY]: {
        dependencies: [API.COMPANY, API.VALUATION, API.PROFITABILITY],
        columns: [
          { header: "Ativo", accessor: "symbol", type: "name" },
          { header: "Empresa", accessor: `${API.VALUATION}.nomeFantasia`, type: "companyName" },
          { header: "Retorno Sobre o Patrimônio", accessor: `${API.PROFITABILITY}.${Fin.ROE}`, type: "varPercent" },
          { header: "Retorno Sobre Bens e Direitos", accessor: `${API.PROFITABILITY}.${Fin.ROI}`, type: "varPercent" },
          { header: "Margem Bruta", accessor: `${API.PROFITABILITY}.${Fin.MARGEM_BRUTA}`, type: "varPercent" },
          { header: "Margem EBITDA", accessor: `${API.PROFITABILITY}.${Fin.MARGEM_EBITDA}`, type: "varPercent" },
          { header: "Margem Operacional", accessor: `${API.PROFITABILITY}.${Fin.MARGEM_OPERACIONAL}`, type: "varPercent" },
          { header: "Margem Líquida dos Acionistas", accessor: `${API.PROFITABILITY}.${Fin.MARGEM_LIQUIDA}`, type: "varPercent" },
        ]
      },
    }

    Object.values(tableColumns).forEach(({ columns }) => {
      columns.forEach(col => {
        col.id = formatKey(col.header)
        col.description = columnsDescription[col.header]
      })
    })

    return tableColumns
  }, [])
}

export default useTableColumns


const formatKey = (key) => key.toLowerCase().replace(/[^\w\s]/g, "").replace(/\s+/g, "_")

const columnsDescription = {
  'Cotação': 'Último preço disponível do ativo (inclui after market no caso de ações). Mostra taxas no caso de juros futuros e Tesouro Direto.',
  'Var. Dia': 'Variação percentual do preço no dia.',
  'Var. Dia (pts)': 'Variação absoluta (em reais, pontos de índice, dólares, pontos percentuais etc) no dia.',
  'Min.': 'Preço mínimo durante o dia.',
  'Max.': 'Maior preço durante o dia.',
  'Compra': 'Melhor oferta de compra.',
  'Venda': 'Melhor oferta de venda.',
  'Negócios': 'Quantidade de negócios durante o dia com o ativo.',
  'Volume Financeiro': 'Volume financeiro, na moeda correspondente, com o ativo no dia.',
  'Var. Ano': 'Variação percentual do preço no ano corrente até o dia.',
  'Var. 12 meses': 'Variação percentual do preço em 12 meses até o dia.',
  'Valor de Mercado': 'Soma do valor de todas as ações da empresa, conforme a cotação mais recente.',
  'Retorno com Dividendos': 'Dividend Yield - Rentabilidade percentual dos dividendos e juros sobre capital próprio pagos por uma empresa em 12 meses em relação ao preço da ação.',
  'Preço/Lucro': 'Medida simples usada para avaliações comparativas de empresas mais "caras ou baratas". Compara o valor de mercado com o lucro líquido e indica o tempo esperado de retorno de um investimento. Quanto maior, mais cara (valorizada) a empresa.',
  'EV/EBITDA': 'Medida simples usada para avaliações comparativas de empresas mais "caras ou baratas". Compara o valor da empresa (Enterprise Value), que é a soma do valor de mercado com a dívida líquida, com o Ebitda, uma métrica de geração de caixa potencial da empresa, em 12 meses. Quanto maior, mais cara (valorizada) a empresa.',
  'Preço/Valor Patrimonial': 'Medida simples usada para avaliações comparativas de empresas mais "caras ou baratas". Compara o valor de mercado da empresa com o capital dos acionistas (PL) contábil. Quanto maior, mais cara (valorizada) a empresa.',
  'Retorno Sobre o Patrimônio': 'Medida de rentabilidade que mede relação percentual entre o lucro líquido e o capital investido pelos acionistas (Patrimônio Líquido), também conhecida pela sigla em inglês RoE, de Return on Equity. Quanto maior, melhor. Dados se referem aos últimos 12 meses, conforme o balanço publicado por cada empresa.',
  'Retorno Sobre Bens e Direitos': 'Medida de rentabilidade que mede relação percentual entre o lucro operacional e o total de bens e direitos que a empresa controla (ativo total), também conhecida pela sigla em inglês como RoA, de Return on Assets. Quanto maior, melhor. Dados se referem aos últimos 12 meses, conforme o balanço publicado por cada empresa.',
  'Margem Bruta': 'Medida de rentabilidade que mede a relação percentual entre o lucro bruto e a receita líquida, ou seja, quantos reais sobram de lucro bruto para a empresa a cada R$ 100 de receita. Quanto maior, melhor. Dados se referem aos últimos 12 meses, conforme o balanço publicado por cada empresa.',
  'Margem EBITDA': 'Medida de rentabilidade que mede a relação percentual entre o Ebitda, uma métrica de geração de caixa potencial da empresa, e a receita líquida, ou seja, quantos reais sobram de Ebitda para a empresa a cada R$ 100 de receita. Quanto maior, melhor. Dados se referem aos últimos 12 meses, conforme o balanço publicado por cada empresa.',
  'Margem Operacional': 'Medida de rentabilidade que mede a relação percentual entre o lucro operacional e a receita líquida, ou seja, quantos reais sobram de lucro operacional para a empresa a cada R$ 100 de receita. Quanto maior, melhor. Dados se referem aos últimos 12 meses, conforme o balanço publicado por cada empresa.',
  'Margem Líquida dos Acionistas': 'Medida de rentabilidade que mede a relação percentual entre o lucro líquido e a receita líquida, ou seja, quantos reais sobram de lucro líquido para os acionistas a cada R$ 100 de receita. Quanto maior, melhor. Dados se referem aos últimos 12 meses, conforme o balanço publicado por cada empresa.',
  'Preço Alvo': 'Média dos preços-alvo projetados pelos analistas que contribuem para a base do Valor PRO.'
}
