import { useEffect, useState } from "react"
import { MdSearch, MdClear } from "react-icons/md"
import QuoteApi from "api/QuoteApi"

export const getOriginColor = originId => {
  switch (originId) {
    case 1: return 'bg-secondary'
    case 2: return 'bg-primary'
    case 3: return 'bg-terciary'
    default: return 'bg-gray-500'
  }
}

function SymbolAutocomplete({ setSymbol, initialSymbol, listClass = "absolute", activeSymbol = false }) {
  const [symbolsList, setSymbolsList] = useState(null)
  // const [symbolSelected, setSymbolSelected] = useState(false)
  const [symbolInputCode, setSymbolInputCode] = useState(initialSymbol)
  const [activeListItem, setActiveListItem] = useState(-1)

  useEffect(() => {
    if (!initialSymbol) return
    QuoteApi.autocompleteSuggestion(initialSymbol).then(r => {
      if (r.length)
        setSymbol(r[0])
      setSymbolsList(null)
    })
    if (!symbolInputCode) {
      setSymbolInputCode(initialSymbol)
    }
  }, [initialSymbol, setSymbol, setSymbolsList])

  async function updateSearchValue(searchValue) {
    setSymbolInputCode(searchValue)
    if (searchValue?.length < 1) {
      setSymbolsList(null)
      setActiveListItem(-1)
      return
    }
    searchValue = searchValue.toUpperCase()
    const response = await QuoteApi.autocompleteSuggestion(searchValue)
    setActiveListItem(-1)
    setSymbolsList(response || [])
  }

  function selectItem(item) {
    setSymbol(item)
    setSymbolsList(null)
    setSymbolInputCode(activeSymbol ? item.symbolCode : '')
    setActiveListItem(-1)
  }

  function clear() {
    setSymbolsList(null)
    setSymbolInputCode('')
  }

  function handlePress(e) {
    // ENTER key
    // eslint-disable-next-line default-case
    switch (e.keyCode) {
      case 8: // Backspace
        // setSymbolSelected(false)
        break
      case 13: // ENTER
        e.preventDefault()
        if (symbolsList?.length) {
          const index = (!activeListItem || activeListItem === -1) ? 0 : activeListItem
          selectItem(symbolsList[index])
        }
        break
      case 27: // Escape
        e.preventDefault()
        setSymbolsList(null)
        break
      case 38: // Arrow Up
        e.preventDefault()
        if (symbolsList?.length)
          setActiveListItem(val => val === 0 ? symbolsList?.length - 1 : val - 1)
          break
      case 40: // Arrow Down
        e.preventDefault()
        if (symbolsList?.length)
          setActiveListItem(val => val + 1 >= symbolsList.length ? 0 : val + 1)
          break
    }
  }

  return (
    <div className="relative pb-1 mb-2">
      <div className="flex items-center">
        <input
          className="input mb-0 font-semibold"
          onChange={e => {updateSearchValue(e.target.value.toUpperCase());}}
          onKeyDown={handlePress}
          value={symbolInputCode}
          autoComplete="off"
          placeholder="Digite o nome ou código do ativo para adicionar" />
        {
          symbolInputCode ?
          <button className="absolute right-4 text-primary" type="button" onClick={(e) =>{ e.preventDefault(); clear();}}><MdClear /></button> :
          <button className="absolute right-4 text-gray-500" type="button"><MdSearch /></button>
        }
      </div>
      {symbolsList && (
        <div className={`rounded bg-white w-full border top-full mt-1 z-50 ${listClass}`}>
          {!symbolsList?.length ? <div className="p-2 text-gray-500 text-sm">Nenhum ativo encontrado...</div> : symbolsList.map((item, index) => (
            <div
              className={`p-2 hover:bg-gray-200 flex space-x-1 cursor-pointer font-semibold items-center ${activeListItem === index && 'bg-blue-100'}`}
              key={'autocomplete_symbol_' + item.symbolCode}
              onClick={() => selectItem(item)}>
              <div className="flex-1 flex flex-wrap items-baseline">
                <h3 className="text-base whitespace-nowrap mr-1">{item.symbolCode}</h3>
                <p className="text-xs text-gray-500 flex-1 whitespace-nowrap">{item.securityDesc}</p>
              </div>
              <p className={`text-xs text-white rounded-md p-1 ${getOriginColor(item.originId)}`}>{item.origin}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default SymbolAutocomplete