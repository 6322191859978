function getUpdatedDataset(key = 'ALL', data) {
  if (!key) {
    return null
  }

  const { original: initialDataset, groups: dataGroups } = data

  let dataset
  if (key === 'ALL') {
    dataset = {
      type: 'ALL',
      data: initialDataset,
      label: `Todos os ativos do IBOVESPA: ${initialDataset.length}`,
      key
    }
  }
  if (!dataset) {
    const legendFound = dataGroups.find(item => item.key === key)
    dataset = legendFound && {
      type: 'LEGEND_GROUP',
      data: legendFound.data.reduce((ac, x) => [...ac, ...x.data], []),
      label: legendFound.fullLegend,
      key
    }
  }
  if (!dataset) {
    const itemFound = dataGroups.reduce((ac, x) => [...ac, ...x.data], []).find(item => item.key === key)
    dataset = itemFound && {
      type: 'DATA_ITEM',
      data: itemFound.data,
      label: `${itemFound.data.length} ${itemFound.data.length === 1 ? 'ativo' : 'ativos'} ${itemFound.label}`,
      key
    }
  }

  if (!dataset) {
    throw new Error(`Not found any data with key ${key}!`)
  }

  return dataset
}

export default getUpdatedDataset
