import { pickBy } from "lodash"
import { ActionTypes as act } from "./types"
import getUpdatedDataset from "./functions/getUpdatedDataset"

function reducer(state, { type, payload }) {

  switch (type) {
    case act.UPDATE_FETCH_RESULTS: {
      const { data, loading, error } = payload

      const mutation = pickBy({ 
        data,
        loading,
        error,
      }, key => key !== undefined)

      if (data) {
        mutation.selectedDataset = getUpdatedDataset(state.selectedDataset?.key || 'ALL', data)
      }

      return { 
        ...state,
        ...mutation,
        lastUpdateTime: new Date()
      }
    }
    case act.SELECT_DATASET: {
      const { dataset, datasetModalIsOpen } = payload
      return { 
        ...state,
        selectedDataset: dataset,
        datasetModalIsOpen
      }
    }
    case act.CLOSE_DETAILS_MODAL: {
      return {
        ...state,
        datasetModalIsOpen: false 
      }
    }
    default: {
      return state
    }
  }

}

export default reducer