import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import Modal from "react-modal"
import CloseButton from "shared/CloseButton"
import { toast } from 'react-toastify'
import SymbolSelectorAutocomplete from "shared/SymbolSelectorAutocomplete"
import { errorCodeHandler } from "helpers/errorCodeHandler"
import NotificationModal from 'shared/modal/NotificationModal'
import useWindowSize from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from "const"
import { useQuotationPanel } from "../context"
import { ModalTypes } from "../types"

function ModalListDetails() {

  const { state, actions } = useQuotationPanel()
  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH_MOBILE

  const isOpen = state.modal?.type === ModalTypes.LIST_DETAILS

  const handleSave = () => {
    actions.openModal(ModalTypes.ADD_ITEMS)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => actions.closeModal()} 
      style={{
        content:
        {
          maxWidth: isMobile ? width : 648,
          margin: "auto",
          marginRight: isMobile ? -40 : "auto",
          marginLeft: isMobile ? -40 : "auto",
        }
      }}>
      <CloseButton onClick={() => actions.closeModal()} />
      <ModalListDetailsContent 
        onSave={handleSave}
        onCancel={() => actions.closeModal()}
        isMobile={isMobile}
        params={state.modal?.params}
      />
    </Modal>
  )
}

export function ModalListDetailsContent({ onSave = () => {}, onCancel = () => {}, isMobile, params = {} }) {

  const { register, getValues, reset } = useForm()
  const { state, actions } = useQuotationPanel()
  const [symbols, setSymbols] = useState([])
  const [listToEdit, setListToEdit] = useState(null)
  const [isOpenNotification, setIsOpenNotification] = useState({ status: false })

  useEffect(() => {
    const { id, updateActiveList } = params
    if (!id && !updateActiveList)
      return
    const list = updateActiveList ? state.activeList : state.lists.find(list => list.id === id)
    if (!list) {
      toast.error('Crie uma lista antes de adicionar ativos!')
      onCancel()
    }
    setListToEdit(list)
    setSymbols(list?.symbols)
    reset({ name: list?.name })
  }, [params, state, reset, onCancel])

  const onSubmit = async (ev) => {
    ev.preventDefault()
    const name = getValues().name.trim().toUpperCase()
    if (!name || typeof name !== 'string') {
      toast.error('O campo nome é obrigatório!')
      return
    }
    if (name.replace(/\s/g, '').toLowerCase() === 'minhacarteira') return toast.error('O painel Minha carteira já existe!')

    try {
      if (!listToEdit) {
        await actions.createList({ name, symbols })
        toast.success(`Painel ${name} criado com sucesso!`)
      } else {
        await actions.updateList(listToEdit, { name, symbols })
        toast.success(`Painel ${name} atualizado com sucesso!`)
      }
      onSave()
    } catch (error) {
      setIsOpenNotification({ status: true, msg: errorCodeHandler(error) })
    }
  }

  const Notification = () => {
    return (
      <NotificationModal
        isOpen={isOpenNotification.status}
        closeModal={() => setIsOpenNotification({ status: false })}
        title={isOpenNotification?.msg}
        style={{
          content: {
            maxWidth: 380,
            borderRadius: 10,
            margin: "auto",
            marginRight: isMobile ? -40 : "auto",
            marginLeft: isMobile ? -40 : "auto",
          }
        }}
        actionButtons='one'
        closeLabel='fechar'
      />
    )
  }


  if (!listToEdit) {
    return (
      <div className="relative flex-1 flex flex-col space-y-3 overflow-y-hidden h-full">
        <h2 className="font-bold text-xl text-primary uppercase">Criar um novo painel</h2>
        <form className="space-y-2" onSubmit={onSubmit}>
          <label htmlFor="panelName" className="label font-semibold text-primary text-lg">Defina o nome do novo painel</label>
          <input autoFocus id="panelName" type="text" className="input" placeholder="Ex: Ativos em observação" {...register('name')} />
        </form>
        <div className="flex-1"></div>
        <div className="flex justify-center space-x-2 mt-3">
          <button className="btn btn-primary" onClick={onSubmit}>Adicionar Ativos</button>
        </div>
        <Notification />
      </div>
    )
  }

  return (
    <div className="relative flex-1 flex flex-col space-y-3 overflow-y-hidden h-full">
      <h2 className="font-bold text-xl text-primary uppercase">{listToEdit ? 'Editar painel' : 'Criar um novo painel'}</h2>
      <form className="space-y-2" onSubmit={onSubmit}>
        <input autoFocus id="panelName" type="text" className="input" placeholder="Ex: Ativos em observação" {...register('name')} />
      </form>

      <SymbolSelectorAutocomplete onChange={setSymbols} symbolsList={symbols} />

      <div className="flex justify-center space-x-2 mt-3">
        <button className="btn btn-secondary" onClick={onCancel}>Cancelar</button>
        <button className="btn btn-primary" onClick={onSubmit}>Salvar</button>
      </div>

      <Notification />
    </div>
  )
}


export default ModalListDetails