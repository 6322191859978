import { useEffect } from "react"
import { useTimer } from "hooks/useTimer"
import { useTraddingSummary } from "../context"
import StatisticApi from "api/StatisticApi"
import getDataGroup from "../functions/getDataGroup"

function useFetchAssetQuotationData(options = { timer: 60000 }) {

  const timer = useTimer(options.timer)
  const { actions } = useTraddingSummary()

  useEffect(() => {
    const fetch = async () => {
      let error = null
      let data = null
      try {
        // actions.updateFetchResults({ loading: true })
        let response = await StatisticApi.fetchIBOVQuotationAsset({ timer })
        data = {
          original: response,
          groups: getDataGroup(response)
        }
      } catch (err) {
        error = err
      } finally {
        actions.updateFetchResults({
          error,
          data,
          loading: false,
        })
      } 
    }

    fetch()
  }, [timer, actions])
}

// debug only
// function getRandomSet(array) {
//   if (!Array.isArray(array)) {
//       throw new Error("Input must be an array");
//   }

//   // Generate a random number n between 0 and the length of the array
//   const n = Math.floor(Math.random() * (array.length + 1));

//   // Shuffle the array using the Fisher-Yates algorithm
//   const shuffled = [...array];
//   for (let i = shuffled.length - 1; i > 0; i--) {
//       const randomIndex = Math.floor(Math.random() * (i + 1));
//       [shuffled[i], shuffled[randomIndex]] = [shuffled[randomIndex], shuffled[i]];
//   }

//   return shuffled.slice(0, n);
// }

export default useFetchAssetQuotationData