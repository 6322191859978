import Modal from "react-modal"
import CloseButton from 'shared/CloseButton'
import { FundDetailsCard } from '../index'

export function ModalShowDetailsFunds({ 
  closeModal, 
  fundActive, 
  size, 
  windowSize, 
  searchPageStructure 
}) {
  return (
    <Modal isOpen={true} onRequestClose={() => closeModal()} style={{ content: { maxWidth: 580, margin: "auto", padding: 0, inset: '5px', borderRadius: '10px' } }}>
      <CloseButton className='absolute z-50 right-5 top-5' size={20} color={'#472F92'} onClick={() => closeModal()} />
      <FundDetailsCard
        fundActive={fundActive}
        size={size}
        windowSize={windowSize}
        searchPageStructure={searchPageStructure}
      />
    </Modal>
  )
}