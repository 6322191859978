import { getToken } from "./settings";

const PING_INTERVAL_MS = 20000; // 20 seconds
const RECONNECT_DELAY_MS = 3000; // 3 seconds
const DEBOUNCE_UNSUBSCRIBE_MS = 100; // Delay for batch processing unsubscribes

class SocketBroker {
  constructor() {
    this.socket = null;
    this.isConnected = false;
    this.activeSocket = false;
    this.pingTimeout = null;
    this.reconnectTimeout = null;

    this.cache = new Map();
    this.subscribed = new Set();
    this.broker = new Map();

    this.desiredSubscriptions = new Set();
    this.debounceUnsubscribeTimeout = null;
  }

  connectSocket() {
    if (this.socket) {
      this.socket.close();
    }

    this.socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
    this.socket.onopen = this.onSocketOpen.bind(this);
    this.socket.onmessage = this.onSocketMessage.bind(this);
    this.socket.onclose = this.onSocketClose.bind(this);
    this.socket.onerror = this.onSocketError.bind(this);
  }

  onSocketOpen() {
    this.activeSocket = true;
    const token = this.getTokenSafely();

    if (this.socket?.readyState !== WebSocket.OPEN) return;

    try {
      this.socket.send(`auth {"access_token": "${token}"}`);
    } catch (err) {
      console.error("[SocketBroker] Failed to send ACCESS_TOKEN:", err);
    }

    this.resubscribeAll();
    this.startPing();
  }

  startPing() {
    this.stopPing(); // Clear any existing ping timers
    this.pingTimeout = setTimeout(() => {
      try {
        if (this.activeSocket && this.socket?.readyState === WebSocket.OPEN) {
          this.socket.send("ping");
          console.debug("[SocketBroker] Ping sent");
          this.startPing();
        }
      } catch (err) {
        console.error("[SocketBroker] Ping failed:", err);
        this.stopPing(); // Stop pinging if there's an error
      }
    }, PING_INTERVAL_MS);
  }

  stopPing() {
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
      this.pingTimeout = null;
    }
  }

  onSocketMessage(event) {
    if (!event?.data) return;

    if (event.data === "pong") {
      console.debug("[SocketBroker] Pong received");
      return;
    }

    try {
      const data = JSON.parse(event.data);
      if (data.code === 401) {
        this.handleAuthError(data);
        return;
      }

      if (data.code === 200 && data.message === "token verified") {
        this.isConnected = true;
        console.log("[SocketBroker] WebSocket token verified");
        this.resubscribeAll();
        return;
      }

      this.dispatchData(data);
    } catch (err) {
      console.error("[SocketBroker] Error handling WebSocket message:", event.data, err);
    }
  }

  handleAuthError(data) {
    if (data.message === "user logged out, duplicate session") {
      if (!window.location.href.includes("sessao")) {
        window.open(`${process.env.REACT_APP_GLOBO_ID_REDIRECT}/sessao/simultanea`, "_self");
      }
      return;
    }

    const token = this.getTokenSafely();
    if (token) {
      try {
        if (this.socket?.readyState === WebSocket.OPEN) {
          this.socket.send(`auth {"access_token": "${token}"}`);
        }
      } catch (err) {
        console.error("[SocketBroker] Failed to reauthenticate:", err);
      }
    }
  }

  dispatchData(data) {
    const key = `${data.symbol}:${data.origin}`;
    const cached = this.cache.get(key)
    const updatedData = { ...cached, ...data }
    this.cache.set(key, updatedData);

    const callbacksObj = this.broker.get(key);
    if (!callbacksObj) return;

    Object.values(callbacksObj).forEach((callback) => {
      try {
        callback(updatedData);
      } catch (err) {
        console.warn(`[SocketBroker] Callback error for key ${key}:`, err);
      }
    });
  }

  onSocketClose() {
    this.activeSocket = false;
    this.isConnected = false;
    this.stopPing();

    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }

    this.reconnectTimeout = setTimeout(() => {
      console.info("[SocketBroker] Reconnecting WebSocket...");
      this.connectSocket();
    }, RECONNECT_DELAY_MS);
  }

  onSocketError(err) {
    console.error("[SocketBroker] WebSocket error:", err);
  }

  resubscribeAll() {
    this.desiredSubscriptions.forEach((item) => {
      try {
        if (this.socket?.readyState === WebSocket.OPEN && !this.subscribed.has(item)) {
          const [symbol, origin] = item.split(':');
          this.socket.send(`subscribe { "symbol": "${symbol}", "origin": "${origin}" }`);
          this.subscribed.add(item);
        }
      } catch (err) {
        console.error(`[SocketBroker] Failed to resubscribe ${item}:`, err);
      }
    });
  }

  subscribe({ symbol, origin }, callback) {
    if (!symbol || !origin || typeof callback !== "function") {
      console.warn("[SocketBroker] Invalid subscription parameters:", { symbol, origin });
      return null;
    }

    const id = this.generateUniqueId();
    const key = `${symbol}:${origin}`;

    if (!this.broker.has(key)) {
      this.broker.set(key, { [id]: callback });
    } else {
      this.broker.get(key)[id] = callback;
    }

    this.desiredSubscriptions.add(key);
    if (this.isConnected && this.socket?.readyState === WebSocket.OPEN && !this.subscribed.has(key)) {
      try {
        this.socket.send(`subscribe { "symbol": "${symbol}", "origin": "${origin}" }`);
        this.subscribed.add(key);
      } catch (err) {
        console.error(`[SocketBroker] Subscription failed for ${key}:`, err);
      }
    }

    this.debounceUnsubscribe();

    return id;
  }

  unsubscribe(id) {
    for (const [key, callbacksObj] of this.broker.entries()) {
      if (callbacksObj[id]) {
        delete callbacksObj[id];
        if (Object.keys(callbacksObj).length === 0) {
          this.broker.delete(key);
          this.desiredSubscriptions.delete(key);
        }
        this.debounceUnsubscribe();
        return true;
      }
    }
    return false;
  }

  debounceUnsubscribe() {
    if (this.debounceUnsubscribeTimeout) {
      clearTimeout(this.debounceUnsubscribeTimeout);
    }

    this.debounceUnsubscribeTimeout = setTimeout(() => {
      const toUnsubscribe = new Set([...this.subscribed].filter((key) => !this.desiredSubscriptions.has(key)));

      toUnsubscribe.forEach((key) => {
        if (this.socket?.readyState === WebSocket.OPEN) {
          const [symbol, origin] = key.split(":");
          try {
            this.socket.send(`unsubscribe { "symbol": "${symbol}", "origin": "${origin}" }`);
            this.subscribed.delete(key);
          } catch (err) {
            console.error(`[SocketBroker] Unsubscribe failed for ${key}:`, err);
          }
        }
      });
    }, DEBOUNCE_UNSUBSCRIBE_MS);
  }

  generateUniqueId() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  getTokenSafely() {
    try {
      return getToken();
    } catch (err) {
      console.error("[SocketBroker] Failed to retrieve token:", err);
      return null;
    }
  }

  getCachedData(symbol, origin) {
    return this.cache.get(`${symbol}:${origin}`) || null;
  }
}

window.SocketBroker = new SocketBroker();
export default window.SocketBroker;
