import AlertsContext from "context/AlertsContext"
import { useContext, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import Modal from "react-modal"
import CloseButton from "shared/CloseButton"
import ControlledInput from "shared/ControlledInput"
import TagsInput from "shared/TagsInput"
import { CompanySelectorAutocomplete } from "shared/CompanyAutocomplete"
import { toast } from "react-toastify"
import CompaniesApi from "api/CompaniesApi"
import { errorCodeHandler } from "helpers/errorCodeHandler"
import NotificationModal from 'shared/modal/NotificationModal'

const criterionOptions = [
  { value: 'company', label: 'Por empresa' },
  { value: 'keyword', label: 'Por palavra-chave' },
]

function ModalAlertNewsForm({ isOpen = true, closeModal, editItem }) {
  const methods = useForm()
  const [selectedOption, setSelectedOption] = useState('keyword')
  const [keywordList, setKeywordList] = useState([])
  const [companyList, setCompanyList] = useState([])
  const { newNewsAlert, updateNewsAlert } = useContext(AlertsContext)
  const { criterion, name } = methods.watch()


  const isFormValid = methods.formState.isValid && criterion && name?.length > 1 && (methods.getValues().criterion === 'keyword' ? keywordList.length : companyList.length)

  const [isOpenNotification, setIsOpenNotification] = useState({ status: false })

  useEffect(() => {
    if (editItem) {
      methods.reset({
        id: editItem.id,
        name: editItem.name,
        criterion: editItem.category,
      })
      setSelectedOption(editItem.category)

      if (editItem.category === 'keyword') {
        setKeywordList(editItem.terms)
      } else if (editItem.category === 'company') {
        loadCompanies(editItem.terms).then(setCompanyList)
      }

    } else {
      methods.reset({
        id: null,
        name: '',
        criterion: 'keyword'
      })
    }
  }, [editItem, isOpen, methods])

  const loadCompanies = async (companiesNames) => {
    try {
      const response = await Promise.all(companiesNames.map(CompaniesApi.search))
      return response.map(([item]) => item).filter(x => !!x)
    } catch (error) {
      toast.error("Falha ao carregar empresas!")
      return []
    }
  }

  const getTerms = (criterion) => {
    switch (criterion) {
      case 'keyword':
        return keywordList
      case 'company': 
        return companyList.map(x => x.name)
      default:
        return null
    }
  }

  const onSubmit = async (data) => {
    const terms = getTerms(data.criterion)
    if (!data.name || !terms?.length) {
      toast.error('Preencha todos os campos do formulário!')
      return
    }

    const params = {
      name: data.name,
      category: data.criterion,
      terms
    }
    try {
      if (data.id) {
        await updateNewsAlert({ id: data.id, ...params })
        toast.success('Alerta alterado com sucesso!')
      } else {
        await newNewsAlert(params)
        toast.success('Alerta criado com sucesso!')
      }
      closeModal()
    } catch (error) {
      setIsOpenNotification({ status: true, msg: errorCodeHandler(error) })
    }
  }

  const handleCriterionOnChange = (value) => {
    setSelectedOption(value.target.value)
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={{ content: { maxWidth: 648, margin: "auto" } }} shouldCloseOnEsc={false}>
      <CloseButton onClick={closeModal} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="flex flex-col h-full">
          <h2 className="font-bold text-xl text-primary uppercase mb-2">Alerta de Notícia</h2>
          <ControlledInput name="name" label="Defina um nome para o alerta*" placeholder="Ex: Empresas ESG" />
          <ControlledInput label="Defina o critério*">
            <select {...methods.register('criterion')} id="condition" className="input" onChange={(value) => handleCriterionOnChange(value)}>
              {criterionOptions.map(({ value, label }) => <option key={'crit_' + value} value={value}>{label}</option>)}
            </select>
          </ControlledInput>
          {
            {
              'keyword': (
                <>
                  <OptionLabel label="Adicione palavras-chave*" selectFn={setSelectedOption} />
                  <TagsInput tagList={keywordList} onChange={(value) => setKeywordList(value)} name="terms" />
                </>
              ),
              'company': (
                <>
                  <OptionLabel label="Adicione os nomes da empresas*" selectFn={setSelectedOption} />
                  <CompanySelectorAutocomplete 
                    companiesList={companyList}
                    onChange={(value) => setCompanyList(value)}
                    name="companies"
                    placeholder="Digite o nome ou código do ativo"
                  />
                </>
              )
            }[selectedOption]
          }

          <span className="mt-2 text-sm">*Campos de preenchimento obrigatório.</span>
          <button className="btn btn-primary mt-4" type="submit" disabled={!isFormValid}>Salvar</button>
        </form>
      </FormProvider>
      <NotificationModal
        isOpen={isOpenNotification.status}
        closeModal={() => setIsOpenNotification({ status: false })}
        title={isOpenNotification?.msg}
        style={{ content: { maxWidth: 400, maxHeight: 272, borderRadius: 10, margin: "auto" } }}
        actionButtons='one'
        closeLabel='fechar'
      />
    </Modal>
  )
}

function OptionLabel({ label, selectFn }) {
  return (
    <div className="flex justify-between">
      <label className="label">{label}</label>
    </div>
  )
}


export default ModalAlertNewsForm