import QuoteApi from "api/QuoteApi"
import CompaniesApi from "api/CompaniesApi"
import BalanceSheetsApi from "api/BalanceSheetsApi"
import { Keys } from "const"
import { KeysFinancial, ExternalAPI as API } from "../types"
import { useQuotationPanel } from "../context"
import { cloneDeep, has } from "lodash"

const fetchMap = {
  [API.COMPANY]: { fetchFn: fetchCompany },
  [API.VALUATION]: { fetchFn: fetchValuation },
  [API.PROFITABILITY]: { fetchFn: fetchProfitability, deps: [API.VALUATION] },
  [API.CONSENSUS]: { fetchFn: fetchConsensus },
}

function useExternalData(APIsToFetch = []) {

  const { state, actions } = useQuotationPanel()

  let shouldFetch = false
  for (const item of state.activeList.symbols) {
    const hasDataAlready = APIsToFetch.every(API => has(state.externalData[item.id], API))
    if (!hasDataAlready) {
      shouldFetch = true
      break
    }
  }

  if (!shouldFetch) return

  // externalData local é um objeto mutável
  const externalData = cloneDeep(state.externalData)

  for (const item of state.activeList.symbols) {
    externalData[item.id] = externalData[item.id] || {}
  }

  fetchAPIs(APIsToFetch, state.activeList.symbols, externalData)
    .then(() => actions.updateExternalData(externalData))
    .catch(err => console.error(err))

}

async function fetchAPIs(APIsToFetch, symbols, externalData) {

  const getFetchArr = (APIs = []) => APIs.map(API => fetchMap[API].fetchFn(symbols, externalData))

  // Control interdependencies
  const APIsDeps = APIsToFetch.map(API => fetchMap[API].deps || []).flat()

  console.log({APIsDeps})

  // Cada fetch irá acrescentar dados no objeto local externalData
  await Promise.all(getFetchArr(APIsDeps))

  // Execute fetch functions for each API
  await Promise.all(getFetchArr(APIsToFetch))

  return externalData
}


async function fetchCompany(symbols, externalData) {
  await Promise.all(
    symbols.map(async (item) => {

      if (API.COMPANY in externalData[item.id]) {
        return externalData[item.id][API.COMPANY]
      }

      try {
        const res = await QuoteApi.marketIndicators(item.origin, item.symbol, `${Keys.NOME},${Keys.CDEC}`)
        if (!res || !res[0]) {
          return null
        }
        externalData[item.id][API.COMPANY] = ({
          response: res[0],
          name: res[0].fieldsMap[0].value,
          cdec: res[0].fieldsMap[1].value
        })
      } catch (error) {
        externalData[item.id][API.COMPANY] = null
      }
    })
  )
}

async function fetchValuation(symbols, externalData) {
  await Promise.all(
    symbols.map(async (item) => {
      if (API.VALUATION in externalData[item.id]) {
        return externalData[item.id][API.VALUATION]
      }

      try {
        const res = await CompaniesApi.searchCompanyInfo(item.symbol).then(e => e ? e[0] : null)
        externalData[item.id][API.VALUATION] = res
      } catch (error) {
        externalData[item.id][API.VALUATION] = null
      }
    })
  )
}


async function fetchConsensus(symbols, externalData) {
  await Promise.all(
    symbols.map(async (item) => {
      if (API.CONSENSUS in externalData[item.id]) {
        return externalData[item.id][API.CONSENSUS]
      }

      try {

        const res = await QuoteApi.consensusRecomendation({ Simbolos: item.symbol, Origem: item.origin })
        externalData[item.id][API.CONSENSUS] = res
      } catch (error) {
        externalData[item.id][API.CONSENSUS] = null
      }
    })
  )
}

async function fetchProfitability(symbols, externalData) {
  const fields = Object.values(KeysFinancial).join(',')
  await Promise.all(
    symbols.map(async (item) => {
      if (API.PROFITABILITY in externalData[item.id]) {
        return externalData[item.id][API.PROFITABILITY]
      }

      try {
        if (!externalData[item.id][API.VALUATION]?.cnpj) {
          await fetchValuation([item], externalData)
        }

        let res = await BalanceSheetsApi.financialIndicators({
          cnpj: externalData[item.id][API.VALUATION].cnpj,
          fields,
          periodo: "trimestral",
          acumulado: false
        })

        if (res.length > Object.keys(KeysFinancial).length) {
          const mostRecentDate = new Date(Math.max(...res.map(obj => new Date(obj.dataFechamento))))
          res = res.filter(obj => new Date(obj.dataFechamento).getTime() === mostRecentDate.getTime())
        }
        if (res.some(obj => obj.natureza === "Consolidado")) {
          res = res.filter(obj => obj.natureza === "Consolidado")
        }
        res = res.reduce((ac, r) => ({
          ...ac,
          [r.id]: r.valor || r.valorIsolado
        }), {})

        externalData[item.id][API.PROFITABILITY] = res
      } catch (error) {
        externalData[item.id][API.PROFITABILITY] = null
      }
    })
  )
}

export default useExternalData