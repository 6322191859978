import { FundsContext } from 'context/FundsContext'
import { useContext, useEffect, useState } from "react"
import { MdOutlineInput, MdClear } from "react-icons/md"
import Modal from "react-modal"
import { toast } from "react-toastify"
import CloseButton from "shared/CloseButton"
import AutoSuggest from "shared/widgets/Funds/components/AutoSuggest"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from 'const'
import useWindowSize from 'hooks/useWindowSize'

export function ModalAddFunds({ isOpen = true, closeModal }) {

  const [activeState, setActiveState] = useState('initial')
  const { width } = useWindowSize()
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH_MOBILE

  useEffect(() => setActiveState('initial'), [isOpen])

  const options = [
    { Icon: MdOutlineInput, label: 'Manualmente', state: 'manual' }
  ]

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={
        {
          content: {
            maxWidth: activeState === 'initial' ? 412 : 602,
            borderRadius: 12,
            margin: "auto",
            marginRight: isMobile ? -40 : "auto",
            marginLeft: isMobile ? -40 : "auto"
          }
        }
      }
    >
      <CloseButton className='absolute right-5 top-5 z-50' size={20} color={'#472F92'} onClick={closeModal} />
      <div className="relative flex flex-col h-full">
        <div className="mb-5">
          <h2 className="font-bold text-xl text-primary uppercase mb-2">Adicionar fundos</h2>
        </div>
        {
          {
            'initial': (
              <div className="space-y-2" >
                {
                  options.map(({ Icon, label, state, disabled }) => (
                    <button className={`w-full h-20 bg-primary grid grid-cols-3 gap-2 items-center rounded-xl text-white p-4 ${disabled ? 'opacity-70' : 'cursor-pointer'}`} onClick={() => disabled || setActiveState(state)} key={state}>
                      <Icon className="justify-self-center text-3xl text-secondary" />
                      <div className="justify-self-start col-span-2 text-left">{label}</div>
                    </button>
                  ))
                }
              </div>
            ),
            'manual': <AddItemsManual onCancel={() => closeModal()} closeModal={closeModal} />
          }[activeState]
        }
      </div>
    </Modal >
  )

}

function AddItemsManual({ onCancel, closeModal }) {
  const { stateFunds, updateListPanel } = useContext(FundsContext)
  const [fundsAdd, setfundsAdd] = useState(stateFunds.listActive?.funds)
  const [disabled, setDisabled] = useState(true)
  const { width } = useWindowSize()

  const save = async () => {
    try {
      await updateListPanel({ Id: stateFunds.listActive.id, Name: stateFunds.listActive.panelName, CNPJNumbers: [...fundsAdd.map(({ cnpjNumberClean }) => cnpjNumberClean)] })
      toast.success(`A lista ${stateFunds.listActive.panelName} foi alterada com sucesso!`)
      closeModal()
    } catch (error) {
      toast.error('Não foi possível atualizar a lista')
      closeModal()
    }
  }

  const deleteItemListAdd = (item) => {
    setfundsAdd((list) => list.filter((el) => el.cnpjNumberClean !== item.cnpjNumberClean))
  }

  const setAddFunds = (fund) => {
    if (fund && fundsAdd.some((x) => x?.cnpjNumberClean === fund.cnpjNumberClean)) {
      toast.warning(`O fundo ${fund.foundName} já está na incluso na lista.`)
      return fundsAdd
    }
    if(fund) setfundsAdd((funds) => {
      setDisabled(false)
      if(funds) return [...funds, fund]
      return [fund]
    })
  }

  return (
    <div className="h-full overflow-hidden flex flex-col space-y-7">
      <AutoSuggest setFund={(fund) => setAddFunds(fund)} width="100%" />
      <div className="h-1/2 flex-grow flex flex-col">
        <span className='text-dark-gray'>Fundos selecionados</span>
        <div className="w-full h-1/2 overflow-auto mini-scrollbar flex-grow border-2 border-[#DBDBDB] rounded-lg">
          <div className="h-full flex flex-col space-y-3 p-5 text-dark-gray">
            {fundsAdd && fundsAdd.map((e) =>
              <div key={`Modal_Add_Funds_${e.cnpjNumberClean}`} className="flex items-center space-x-1">
                <span className='line-clamp-2'>{e.foundName}</span>
                <MdClear onClick={() => deleteItemListAdd(e)} className="flex-none cursor-pointer text-primary" />
              </div>)}
          </div>
        </div>
        {fundsAdd && fundsAdd.length > 0 && <span className="text-xs text-end mt-[5px] text-dark-gray">{`${fundsAdd.length} fundos selecionados`}</span>}
      </div>
      <div className={`flex justify-center ${width < 400 ? 'flex-wrap space-y-3' : 'space-x-3'} mt-3`}>
        <button className={`btn btn-secondary ${width < 400 && 'w-full'}`} onClick={onCancel}>Cancelar</button>
        <button className={`btn ${!disabled ? 'btn-primary text-secondary' : 'bg-[#EDEDED] text-grayDisabled'} ${width < 400 && 'w-full'}`} onClick={save} disabled={disabled}>Adicionar</button>
      </div>
    </div>
  )

}