import { memoize } from "lodash"
import { BASE_URL, SearchParams, getApiHeaders, memoizeResolver, customFetch } from "./settings"

const URL = 'Companies'

const Api = {

  search: memoize(async function (queryTerm = '') {

    if (queryTerm?.length < 2) return []

    try {
      const response = await customFetch(`${BASE_URL}/${URL}/search?${SearchParams({ pesquisa: queryTerm })}`, {
        method: 'GET',
        headers: getApiHeaders()
      })

      if (!response.ok) 
        throw new Error(`[Error ${response.status}] ${response.statusText}`)

      const res = await response.json()
      if (!res?.output) 
        throw new Error('Missing output in response!')
      if (!Array.isArray(res.output))
        throw new Error('No results found!')
    
      const r = res.output.reduce((ac, x) => {
        const lang0 = x.languages[0]
        const found = ac.find(item => item.title === lang0.RazaoSocial)
        if (found) {
          found.symbols.push(...x.languages.map(item => item.CodigoAtivo))
          return ac
        }
        return [...ac, { title: lang0?.RazaoSocial, name: lang0?.NomeFantasia, symbols: [lang0?.CodigoAtivo] }]
      }, [])
      return r

    } catch (error) {
      console.error(error)
      return []
    }

  }),

  searchCompanyInfo: memoize(async (simbolo) => {
    
    const response = await customFetch(`${BASE_URL}/${URL}/searchcompanyinformation?${SearchParams({ simbolo })}`, { method: 'GET', headers: getApiHeaders() })
    if (response.status === 200) return response.json()
  }, memoizeResolver)

}

export default Api