import { usePermission } from "hooks/usePermission"
import { useMemo } from "react"
import { useQuotationPanel } from "../context"
import { ModalTypes } from "../types"

function useContextMenuOptions() {

  const permission = usePermission('QuotationPanel')
  const { actions } = useQuotationPanel()

  const contextMenuOptions = useMemo(() => {

    const createModalPermission = permission?.childrens?.some(({ key }) => key === "CreatePanel")

    return [
      {
        label: "Adicionar ativos",
        type: 'restricted',
        onClick: () => actions.openModal(ModalTypes.ADD_ITEMS)
      },
      {
        label: "Editar painel",
        type: 'restricted',
        onClick: () => actions.openModal(ModalTypes.LIST_DETAILS, { updateActiveList: true })
      },
      {
        label: "d1",
        type: "divider"
      },
      {
        label: "Criar um novo painel",
        onClick: () => actions.openModal(ModalTypes.LIST_DETAILS),
        blocked: !createModalPermission
      },
      {
        label: "Gerenciar painéis",
        onClick: () => actions.openModal(ModalTypes.MANAGE_PANELS),
        blocked: !createModalPermission
      },
    ]
  }, [permission, actions])

  return contextMenuOptions
}

export default useContextMenuOptions