import { cloneElement, memo, useMemo } from "react"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { SimpleTooltip } from "shared/HelpTooltip"
import { useCustomId } from "hooks/useCustomId"
import { get, throttle } from "lodash"

const { MdArrowDropUp, MdArrowDropDown } = require("react-icons/md")

export const OrderByColumnContext = createContext({
  orderBy: null,
  sortedData: [],
  setOrderBy: (orderBy) => { },
})

export const OrderByColumnTrigger = memo(
  ({ children, columnName, options = { fallback: null } }) => {
    const { orderBy, setOrderBy } = useContext(OrderByColumnContext)
    return cloneElement(children, {
      onClick: () =>
        setOrderBy({
          key: columnName,
          order: orderBy.key === columnName && orderBy.order === -1 ? 1 : -1,
          ...options,
        }),
    })
  }
)

export const OrderByColumnDisplay = memo(
  ({ classNameTooltip, columnName, tooltip, onClick }) => {
    const id = useCustomId("OrderByColumnDisplay")
    const { orderBy } = useContext(OrderByColumnContext)
    const isSelected = (ordering) =>
      orderBy.key === columnName && orderBy.order === ordering

    return (
      <>
        <div onClick={onClick} className={`flex flex-col`} id={id}>
          <MdArrowDropUp
            className={`text-xl ${isSelected(1) ? "text-primary" : "text-[#BEBEBE]"
              }`}
          />
          <MdArrowDropDown
            className={`text-xl -mt-3 ${isSelected(-1) ? "text-primary" : "text-[#BEBEBE]"
              }`}
          />
        </div>
        {id && tooltip && (
          <SimpleTooltip
            className={classNameTooltip}
            anchor={`#${id}`}
            value={tooltip}
          />
        )}
      </>
    )
  }
)

export function OrderByColumnContextProvider({
  children,
  data,
  defaultColumn,
  defaultOrder = 1,
}) {
  const [orderBy, setOrderBy] = useState({
    key: defaultColumn,
    order: defaultOrder,
  })
  const [sortedData, setSortedData] = useState(data)

  const throttledSetOrderBy = useMemo(() => throttle(setOrderBy, 300), [])

  const sortFn = useCallback(({ key, order, fallback, arr }) => {
    return [...arr].sort((a, b) => {
      const valueA = get(a, key) || get(a, fallback)
      const valueB = get(b, key) || get(b, fallback)

      // Handle null/undefined values
      if (valueA == null || valueB == null) {
        return valueA == null ? 1 : -1
      }

      // Numeric comparison
      if (typeof valueA === "number" && typeof valueB === "number") {
        return (valueA - valueB) * order
      }

      // String comparison using localeCompare
      return String(valueA).localeCompare(String(valueB)) * order
    })
  }, [])

  useEffect(() => {
    if (!orderBy.key) {
      setSortedData(data)
      return
    }
    if (!data) {
      setSortedData([])
      return
    }
    const orderedData = sortFn({ ...orderBy, arr: data })
    setSortedData(orderedData)
  }, [data, orderBy, sortFn])

  return (
    <OrderByColumnContext.Provider
      value={{ orderBy, setOrderBy: throttledSetOrderBy, sortedData }}
    >
      {children}
    </OrderByColumnContext.Provider>
  )
}
