import { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import Modal from "react-modal"
import { MdOutlineStar } from 'react-icons/md'
import { FundsContext, FundsContextProvider } from 'context/FundsContext'
import { OrderByColumnContext, OrderByColumnContextProvider } from 'context/OrderByColumnContext'
import useMobile from 'hooks/useMobile'
import useTitle from "hooks/useTitle"
import useWindowSize from "hooks/useWindowSize"
import { convertToInternationalCurrencySystem, format } from 'helpers/numberHelper'
import AutoSuggest from 'shared/widgets/Funds/components/AutoSuggest'
import { BtnToggleFavoriteList } from 'shared/widgets/Funds/components/FavoriteList'
import { Lists } from 'shared/widgets/Funds/views/Lists'
import WidgetContextualMenu from 'shared/WidgetContextualMenu'
import CloseButton from "shared/CloseButton"
import DeniedPermissionWidget from "shared/DeniedPermissionWidget"
import { HelpTooltip } from "shared/HelpTooltip"
import InfinityScrollContainer from 'shared/InfinityScrollContainer'
import { RatingCustom } from 'shared/RatingCustom'
import Tabs from 'shared/Tabs'
import { WidgetLinkTitle, WidgetTitle } from "shared/WidgetTitle"
import dollarSign from 'assets/imgs/dollar_sign.svg'
import rotateDevice from 'assets/imgs/rotate-device.svg'
import { FUNDS_TOOLTIPS } from 'const'

import { ModalAddFunds } from "shared/widgets/Funds/modals/ModalAddFunds"
import { ModalCreateFundsList } from "shared/widgets/Funds/modals/ModalCreateFundsList"
import { ModalEditFunds } from "shared/widgets/Funds/modals/ModalEditFunds"
import { ModalManageFunds } from "shared/widgets/Funds/modals/ModalManageFunds"

function useFunds() {

  const [addFundsModalIsOpen, setAddFundsModalIsOpen] = useState(false)
  const [editFundsModalIsOpen, setEditFundsModalIsOpen] = useState(false)
  const [createListModalIsOpen, setCreateListModalIsOpen] = useState(false)
  const [saveListIsOpen, setSaveListIsOpen] = useState(null)
  const [manageModalIsOpen, setManageModalIsOpen] = useState(false)
  const [isOpenPopUp, setIsOpenPopUp] = useState(false)

  //const permission = usePermission('Funds')
  const contextMenuOptions = [
    { label: "Adicionar fundos", type: 'restricted', onClick: () => setAddFundsModalIsOpen(true) },
    { label: "Editar lista", type: 'restricted', onClick: () => setEditFundsModalIsOpen(true) },
    // { label: 'Salvar como lista', onClick: () => setSaveListIsOpen(true) },
    { label: "d1", type: "divider" },
    { label: "Criar uma nova lista", onClick: () => setCreateListModalIsOpen(true) },
    { label: "Gerenciar listas", onClick: () => setManageModalIsOpen(true) },
  ]

  const { isMobile, widthMobile } = useMobile()

  useEffect(() => {
    if (widthMobile) setIsOpenPopUp(true)
    else setIsOpenPopUp(false)
  }, [widthMobile])

  function createNewListFunds() {
    setCreateListModalIsOpen(true)
  }

  function editListSelected() {
    setEditFundsModalIsOpen(true)
  }

  const popUpRotateDevice = (tabCard) => {
    return isOpenPopUp && tabCard?.index === 0 && <div onClick={() => setIsOpenPopUp(false)} className='absolute z-50 flex items-center justify-center w-full h-full'>
      <img src={rotateDevice} alt="Recomendação para girar o dispositivo" />
    </div>
  }

  const linkMethodology = () => {
    return <div className='mt-[10px] pb-4 flex flex-col text-dark-gray text-xs font-semibold'>
      <span>O Ranking Valor não é uma recomendação de investimentos. </span>
      <Link className='underline' to={'/metolodogia-ranking-fundos-valor'}>Veja metodologia completa e disclaimer.</Link>
    </div>
  }

  const modals = <>
    {addFundsModalIsOpen && (<ModalAddFunds closeModal={() => setAddFundsModalIsOpen(false)} />)}
    {editFundsModalIsOpen && (<ModalEditFunds closeModal={() => setEditFundsModalIsOpen(false)} />)}
    {createListModalIsOpen && (<ModalCreateFundsList closeModal={() => setCreateListModalIsOpen(false)} />)}
    {saveListIsOpen && (<ModalCreateFundsList closeModal={() => setSaveListIsOpen(null)} saveList={saveListIsOpen} />)}
    {manageModalIsOpen && (<ModalManageFunds closeModal={() => setManageModalIsOpen(false)} createNewList={createNewListFunds} editList={editListSelected} />)}
  </>

  return {
    isMobile,
    addFundsModalIsOpen,
    createListModalIsOpen,
    saveListIsOpen,
    manageModalIsOpen,
    contextMenuOptions,
    modals,
    setAddFundsModalIsOpen,
    setCreateListModalIsOpen,
    setSaveListIsOpen,
    setManageModalIsOpen,
    popUpRotateDevice,
    linkMethodology
  }
}

function FundsWidgets(props) {

  const sizeView = props.desktop
  const [tabCard, setTabCard] = useState(null)
  const use = useFunds()

  const [sortedDataToShow, setSortedDataToShow] = useState([])
  const [scrollActive, setScrollActive] = useState(false)
  const [listCategoriesExisting, setListCategoriesExisting] = useState(false)
  
  useEffect(() => {
    const tabSession = sessionStorage.getItem('FundsTabSelected')
    if (tabSession) {
      const parseTabSession = JSON.parse(tabSession)
      setTabCard({ index: parseTabSession.index })
    } else setTabCard({ index: 0 })
  }, [])

  return (
    <FundsContextProvider mobile={use.isMobile} widgetProps={props}>
      {use.popUpRotateDevice(tabCard)}
      <Container>
        <ContainerInfinityScroll
          dataToShow={{sortedDataToShow, setSortedDataToShow}}
          setScrollActive={setScrollActive}
          setListCategoriesExisting={setListCategoriesExisting}
          classContainer={`relative ${tabCard?.index === 0 ? 'overflow-auto mini-scrollbar mini-scrollbar-x' : 'overflow-hidden'} bg-white h-full rounded flex flex-col p-4`}
        >
          <HeaderFunds props={props} tabSelected={tabCard?.index} setTab={setTabCard} />
          <DeniedPermissionWidget widgetSize={sizeView || 1} widgetType="Funds">
            {{
              0: <Lists
                size={sizeView}
                contextMenuOptions={use.contextMenuOptions}
                scrollActive={scrollActive}
                dataToShow={{sortedDataToShow, setSortedDataToShow}}
                listCategoriesExisting={listCategoriesExisting}
              />, 
              1: <FundStructureCard size={sizeView} />
            }[tabCard?.index]}
          </DeniedPermissionWidget>
        </ContainerInfinityScroll>
      </Container>
      {use.modals}
    </FundsContextProvider>
  )
}

function FundsPage() {
  useTitle('Fundos')

  const [tabCard, setTabCard] = useState({ index: 0 })
  const use = useFunds()

  const [sortedDataToShow, setSortedDataToShow] = useState([])
  const [scrollActive, setScrollActive] = useState({ x: false, y: false })
  const [listCategoriesExisting, setListCategoriesExisting] = useState(false)
  
  return (
    <FundsContextProvider mobile={use.isMobile} pageProps={true}>
      {use.popUpRotateDevice(tabCard)}
      <Container>
        <ContainerInfinityScroll
          dataToShow={{sortedDataToShow, setSortedDataToShow}}
          setScrollActive={setScrollActive}
          setListCategoriesExisting={setListCategoriesExisting}
          classContainer={`relative overflow-auto mini-scrollbar mini-scrollbar-x h-full bg-white rounded flex flex-col p-4`}
        >
          <HeaderFunds tabSelected={tabCard?.index} setTab={setTabCard} />
          {{ 
            0: <Lists
              contextMenuOptions={use.contextMenuOptions}
              scrollActive={scrollActive}
              dataToShow={{sortedDataToShow, setSortedDataToShow}}
              listCategoriesExisting={listCategoriesExisting}
            />,  
            1: <FundStructureCard /> 
          }[tabCard?.index]}
        </ContainerInfinityScroll>
      </Container>    
      {use.modals}
    </FundsContextProvider>
  )
}

function HeaderFunds({ props, tabSelected, setTab }) {

  const { tabs, setTabSelected } = useContext(FundsContext)
  const id = props?.id

  function changeTabs(args) {
    setTabSelected(args)
    setTab(args)
  }

  return (
    <div className='sticky left-0'>
      <div className="drag-handle">
        {props
          ? <>
            <WidgetContextualMenu removeItem={() => props.removeItem(id)} />
            <WidgetLinkTitle title="Fundos" route="/fundos" />
          </>
          :
          <WidgetTitle title="Fundos" />}
      </div>
      <Tabs
        tabs={tabs}
        activeTabIndex={tabSelected}
        onChange={(args) => changeTabs(args)}
        parentClass={`flex space-x-[50px] mt-[22px] mb-[30px]`}
        tabClass={'pb-2 text-base'}
      />
    </div>
  )
}

function Container({children}) {
  const { stateFunds } = useContext(FundsContext)
  const fundsData = stateFunds?.listActive?.funds || []
  return (
    <OrderByColumnContextProvider data={fundsData} defaultColumn="profitabilityThreeYears" defaultOrder={-1} >
      {children}
    </OrderByColumnContextProvider>
  )
}

function ContainerInfinityScroll({children, dataToShow, classContainer, setScrollActive = () => {}, setListCategoriesExisting = () => {}}) {
  const { tabSelected, perCategory, formattedPerCategory } = useContext(FundsContext)
  const { sortedData } = useContext(OrderByColumnContext)

  const loadMore = useCallback((scrollEvent) => {
    if (!dataToShow.sortedDataToShow) return
    const newItems = sortedData.slice(
      dataToShow.sortedDataToShow.length,
      dataToShow.sortedDataToShow.length + 20
    )
    dataToShow.setSortedDataToShow((prev) => [...prev, ...newItems])
  }, [dataToShow, sortedData])

  function getCategoriesExistingInList(list) { 
    let listFormatted
    if (perCategory) listFormatted = formattedPerCategory(list)
    if (!perCategory) listFormatted = list
    const listArray = new Map()
    const categoriesExisting = listFormatted.filter((e, i, a) => i === a.findIndex((el) => {
      return el?.category === e?.category
    })).map((fund) => fund?.cnpjNumberClean)
    listFormatted.forEach((e, i) => {
      if (categoriesExisting.includes(e?.cnpjNumberClean)) listArray.set(e?.cnpjNumberClean, i)
    })
    setListCategoriesExisting(listArray)
  }

  useEffect(() => {
    if (sortedData.length > 0) {
      getCategoriesExistingInList(sortedData)
      dataToShow.setSortedDataToShow(sortedData.slice(0, 20))
    }
  }, [sortedData])

  return (
    tabSelected?.index === 0
      ? <InfinityScrollContainer className={`${classContainer}`} loadMore={loadMore} onScroll={e => setScrollActive({ x: (e.target.scrollLeft !== 0), y: e.target.scrollTop > 175 })}>
        {children}
      </InfinityScrollContainer>
      : <div className={`${classContainer}`}>
        {children}
      </div>
  )
}

function FundStructureCard({ size }) {
  const { fundActive, selectedFund, searchPageStructure } = useContext(FundsContext)
  const [isOpen, setIsOpen] = useState(false)
  const windowSize = useWindowSize()

  function fundSelected(list) {
    if (list) {
      if (list.cnpjNumber) setIsOpen(true)
      selectedFund(list)
    } else {
      selectedFund([])
    }
  }

  function closeModal() {
    selectedFund([])
    setIsOpen(false)
  }

  return (
    <div className='h-full flex flex-col gap-[30px] overflow-hidden'>
      <AutoSuggest activeFund={fundActive} setFund={(list) => fundSelected(list)} isWidget={size} />
      {windowSize.width < 450 && !fundActive &&
        <div className='h-full items-center flex justify-center p-[30px] border-2 border-[#dbdbdb] rounded-xl'>
          <div className='w-[360px] flex flex-col items-center space-y-[30px]'>
            <img src={dollarSign} alt="cifrão de dolar" />
            <span className='text-dark-gray text-center text-base font-semibold uppercase leading-[26px]'>escolha um fundo</span>
            <span className='text-dark-gray text-center text-base font-semibold leading-[26px]'>E confira quantas estrelas ele tem no Ranking Valor, compare seu desempenho com o de outros da mesma categoria e saiba onde encontrá-lo.</span>
          </div>
        </div>}
      {windowSize.width > 450
        ? <div className='flex flex-col overflow-auto grow mini-scrollbar'>
          <FundDetailsCard
            fundActive={fundActive}
            size={size}
            windowSize={windowSize}
            searchPageStructure={searchPageStructure}
          />
        </div>
        : <Modal isOpen={isOpen} onRequestClose={() => closeModal()} style={{ content: { maxWidth: 648, margin: "auto", padding: 0, inset: '5px' } }}>
          <CloseButton className='absolute z-50 right-5 top-5' size={20} color={'#472F92'} onClick={() => closeModal()} />
          <FundDetailsCard
            fundActive={fundActive}
            size={size}
            windowSize={windowSize}
            searchPageStructure={searchPageStructure}
          />
        </Modal>
      }
    </div>
  )
}

function FundDetailsCard({ fundActive, size, windowSize, searchPageStructure }) {
  const notLabel = ['CNPJ']
  const use = useFunds()
  return (
    <div className={`${!fundActive ? size ? 'h-full items-center' : 'h-full items-center' : ''} overflow-auto mini-scrollbar flex justify-center p-[30px] pb-[20px] ${windowSize.width > 450 && 'border-2 border-[#dbdbdb] rounded-xl'}`}>
      {
        !fundActive
          ? windowSize.width > 450 && <div className='w-[360px] flex flex-col items-center space-y-[30px]'>
            <img src={dollarSign} alt="cifrão de dolar" />
            <span className='text-dark-gray text-center text-base font-semibold uppercase leading-[26px]'>escolha um fundo</span>
            <span className='text-dark-gray text-center text-base font-semibold leading-[26px]'>E confira quantas estrelas ele tem no Ranking Valor, compare seu desempenho com o de outros da mesma categoria e saiba onde encontrá-lo.</span>
          </div>
          : <div className={`flex flex-col space-y-[30px]`}>
            <div>
              <div className='flex'>
              <Item label={null} value={fundActive.foundName} type='string' classContainer='w-full' classTittle='text-primary' cardSizeInMobile={windowSize.width < 392}>
                <BtnToggleFavoriteList fund={fundActive} />
              </Item>
              </div>
              <div>
                <span className='text-xs font-bold text-dark-gray'>{searchPageStructure.find(x => x.label === "CNPJ").content}</span>
              </div>
            </div>
            <div className={`flex flex-wrap ${size?.w === 1 && 'justify-between'} gap-y-[30px] gap-x-5`}>
              {searchPageStructure.map(({ label, content, type, date }) => !notLabel.includes(label) && <Item key={label} label={label} value={fundActive[content]} type={type} date={date} classTittle={label === 'Gestor' && 'text-base'} cardSizeInMobile={windowSize.width < 392 ? 'w-full' : 'w-[150px]'} />)}
            </div>
            <div>
              <details open={fundActive.whereFind.length} className='relative'>
                <summary className={`text-dark-gray cursor-pointer`}>Onde encontrar</summary>
                <div>
                  {fundActive.whereFind.length
                    ? fundActive.whereFind.map(({ id, name }, i) => <span key={`#where_find_${id}`} className='font-bold text-dark-gray'>{`${name}`}{fundActive.whereFind.length !== (i + 1) && <span>, </span>}</span>)
                    : <span className='font-bold text-dark-gray'>-</span>}
                </div>
              </details>
            </div>
            {use.linkMethodology()}
          </div>
      }
    </div>
  )
}

function Item({ label, value, type, classTittle = '', date, classContainer, cardSizeInMobile, children }) {
  const { listCategories, categoriesGroup } = useContext(FundsContext)
  const notTootip = [null, 'CNPJ']
  const generationId = JSON.stringify(Math.random()).replace('.', '_')
  let valueFormatted
  if(value === undefined) return
  if (type === 'specificStringFormat') {
    let valueOne = value[0] === 0 ? '0,00%' : value[0] ? `${Number(value[0]).toFixed(2)}%`.replace('.', ',') : '-'
    let valueTwo = value[1] === 0 ? '0%' : value[1] ? `${format(value[1], 'float', 2)}%` : '-'
    valueFormatted = `${valueOne}  e  ${valueTwo}`
  }
  if (type === 'string') valueFormatted = value
  if (type === 'bigCurrency') valueFormatted = `R$ ${convertToInternationalCurrencySystem(value)}`
  if (type === 'currency') valueFormatted = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 0, style: 'currency', currency: 'BRL' }).format(value)
  if (type === 'integer') valueFormatted = format(value, type)
  if (type === 'percent') valueFormatted = `${Number(value).toFixed(2)}%`.replace('.', ',')
  if (type === 'numberToString') valueFormatted = `${value || value === 0 ? `D+${value}` : '-'}`
  if (type === 'starGraphic' && (value || value === 0)) {
    if (value === 0) return <div className={`${cardSizeInMobile} flex flex-col justify-between`}>
      <div className='space-x-1'>
        <span>{label}</span>
        <HelpTooltip id={`starGraphic_${generationId}`} className="text-xs leading-4 rounded font-bold" place={'top'}>
          <span className='w-[232px] p-[5px]'>{FUNDS_TOOLTIPS[label]}</span>
        </HelpTooltip>
      </div>
      <div className='text-sm text-dark-gray'>Menos de 5 anos</div>
    </div>
    return <RatingCustom
      id={`starGraphic_${generationId}`}
      label={label}
      icon={<MdOutlineStar />}
      position='top'
      classContainer={`${cardSizeInMobile}`}
      value={value}
      description={<div className='flex flex-col'>
        <span>{FUNDS_TOOLTIPS[label]}</span>
        {date && <span>{date}</span>}
      </div>}
    />
  }
  if (type === 'barGraphic' && (value || value === 0)) {
    return <RatingCustom
      id={`barGraphic_${generationId}`}
      idBar={`idBarGraphic_${generationId}`}
      label={label}
      position='top'
      classContainer={`${cardSizeInMobile}`}
      value={value}
      description={<div className='flex flex-col'>
        <span>{FUNDS_TOOLTIPS[label]}</span>
        {date && <span>{date}</span>}
      </div>}
    />
  }

  if (type === 'string' && label === 'Categoria' && listCategories) {
    const category = listCategories.find(({ name }) => name === value)
    const { name } = categoriesGroup.find((group) => group?.subCatId.some(({ id }) => id === category.id))
    return (
      <div className={`${classContainer ? classContainer : cardSizeInMobile}`}>
        <div className='text-sm font-bold text-dark-gray'>
          <span className='text-base font-normal'>{label}</span>
          <HelpTooltip id={type + generationId + "_search_funds"} classContainer="ml-1" className="text-xs leading-4 font-bold rounded" place='top'>
            <div className='w-[232px] p-[5px] flex flex-col'>
              <span>{FUNDS_TOOLTIPS[label]}</span>
              {date && <span>{date}</span>}
            </div>
          </HelpTooltip>
          <div>
            <span>{`${name} /`}</span>
            <span>{value}</span>
            <HelpTooltip id={label + generationId + "_search_funds_categories"} classContainer="ml-1" className="text-xs leading-4 font-bold rounded" place='top'>
              <span className='w-[232px] p-[5px] flex'>{FUNDS_TOOLTIPS[valueFormatted]}</span>
            </HelpTooltip>
          </div>
        </div>
      </div>
    )
  }

  if (!value) valueFormatted = <span className='text-dark-gray'>-</span>
  return (
    <div className={`${classContainer ? classContainer : cardSizeInMobile} flex flex-col text-dark-gray`}>
      <div className='space-x-1'>
        <span>{label}</span>
        {!notTootip.includes(label) && <HelpTooltip id={type + generationId + "_search_funds_cards"} className="text-xs leading-4 font-bold rounded" place='top'>
          <div className='w-[232px] p-[5px] flex flex-col'>
            <span>{FUNDS_TOOLTIPS[label]}</span>
            {date && <span>{date}</span>}
          </div>
        </HelpTooltip>}
      </div>
      <span className={`${classTittle} flex items-center font-bold space-x-1 ${type === 'percent' && (value > 0 ? 'text-profit' : 'text-legend-loss')}`}>
        <span>{valueFormatted}</span>
        {children}
      </span>
    </div>
  )
}



export { FundsPage, FundsWidgets, FundDetailsCard }

