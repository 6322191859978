import { HelpTooltip, SimpleTooltip } from "shared/HelpTooltip"

export function RatingCustom({id, idBar, icon, label, value, position, description, classContainer, classContent }) {
  let valueShow
  if(!value) valueShow = 0
  valueShow = value
  return (
    <div className={`${classContainer}`}>
      <span className='space-x-1'>
        <span>{label}</span>
        {description && <HelpTooltip id={id + "_hanking_custom"} className="text-xs leading-4 rounded font-bold" place={position}>
          <span className="w-[232px] p-[5px] flex">{description}</span>
        </HelpTooltip>}
      </span>
      {icon 
        ? <div className='flex'>
          {[...Array(4)].map((_,i) => <div key={`iconPosition${i}`} className={`${valueShow < i+1 ? 'text-[#dbdbdb]' : 'text-primary'}`}>{icon}</div>)}
        </div>
        : <>
          <div id={idBar} className={`${classContent} w-full flex items-end gap-x-1`}>    
            {[...Array(10)].map((_, i) => <div key={`barPosition${i}`} className={`${valueShow < i+1 ? 'bg-[#dbdbdb]' : 'bg-primary'} w-1 flex-none`} style={{height:`${12+(i*2)}px`}}></div>)} 
          </div>
          <SimpleTooltip anchor={'#' + idBar}>
            <span className="flex-none text-xs text-white">{`${valueShow} de 10`}</span>
          </SimpleTooltip>
        </> 
      }
    </div>
  )
}