import { ActionTypes } from "./types"

const actions = (dispatch) => {
  return {
    updateFetchResults: ({ data, loading, error }) => {
      dispatch({
        type: ActionTypes.UPDATE_FETCH_RESULTS,
        payload: { data, loading, error }
      })
    },
    selectDataset: ({ dataset, datasetModalIsOpen }) => {
      dispatch({
        type: ActionTypes.SELECT_DATASET,
        payload: { dataset, datasetModalIsOpen }
      })
    },
    closeDetailsModal: () => {
      dispatch({
        type: ActionTypes.CLOSE_DETAILS_MODAL
      })
    }
  }
}

export default actions