import AuthContext from "context/AuthContext";
import { usePermissionValidation } from "hooks/usePermission";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WidgetContextualMenu from "shared/WidgetContextualMenu";
import { WidgetTitle, WidgetLinkTitle } from "shared/WidgetTitle";
import useTitle from "hooks/useTitle";
import { LAYOUT_BREAKPOINT_WIDTH } from "const";
import useWindowSize from "hooks/useWindowSize";
import DeniedPermissionWidget from "shared/DeniedPermissionWidget";

export function GenericWidget({ id, ...props }) {
  const { genericModules } = useContext(AuthContext);
  const [data, setData] = useState({});
  const genericModuleId = props.params.id;
  const widgetHeight = document.getElementById(id)?.clientHeight - 68;

  useEffect(() => {
    setData(genericModules?.find((x) => +x.id === +genericModuleId));
  }, [setData, genericModules, genericModuleId]);

  return (
    <div className="bg-white h-full rounded flex flex-col">
      <div className="drag-handle pt-4 px-4">
        <WidgetContextualMenu removeItem={() => props.removeItem(id)} />
        {(data?.title || props.params.title) ? (
          <WidgetLinkTitle
            title={data.title}
            route={`/modulo/${genericModuleId}`}
          />
        ) : (
          <WidgetTitle title="Módulo descontinuado" />
        )}
      </div>
      <DeniedPermissionWidget widgetType={genericModuleId}>
        <div className="flex-1 overflow-auto mini-scrollbar pb-4 px-4">
          {data?.sourceCode ? (
            <iframe
              height={widgetHeight}
              width={"full"}
              src={data.sourceCode}
              className="margin-auto w-full"
              title="Iframe"
            />
          ) : (
            <WarnDiscontinuedModule id={id} {...props} />
          )}
        </div>
      </DeniedPermissionWidget>
    </div>
  );
}

function WarnDiscontinuedModule({ id, ...props }) {
  const { width } = useWindowSize();
  const widgetSize = width >= LAYOUT_BREAKPOINT_WIDTH ? props.desktop?.w : 1;

  return (
    <div
      className={`grid grid-flow-dense ${
        widgetSize === 2 ? "grid-cols-2" : widgetSize === 3 ? "grid-cols-3" : ""
      }`}
    >
      <div className="rounded-xl bg-light-gray p-8 text-center mt-4 space-y-4 col-1 leading-6">
        <h3 className="text-xl font-bold uppercase">
          Este módulo foi descontinuado
        </h3>
        <p className="font-semibold">
          Clique no botão abaixo para retirá-lo da sua área de trabalho.
          <br />
          Em caso de dúvidas, fale conosco usando o chat.
        </p>
        <button
          className="btn btn-primary inline-block"
          onClick={() => props.removeItem(id)}
        >
          Excluir módulo
        </button>
      </div>
    </div>
  );
}

export function GenericPage() {
  const { genericModules } = useContext(AuthContext);
  const [data, setData] = useState({});
  useTitle(data?.title);
  const { id: genericModuleId } = useParams();
  const { validatePermissionAndRedirect } =
    usePermissionValidation(genericModuleId);

  useEffect(() => {
    setData(genericModules.find((x) => +x.id === +genericModuleId));
  }, [setData, genericModules, genericModuleId]);

  return (
    (validatePermissionAndRedirect() || data) && (
      <div className="bg-white min-h-full rounded flex flex-col">
        <div className="pt-4 px-4 overflow-y-visible">
          <WidgetTitle title={data.title} />
          <div className="h-full overflow-hidden">
            <iframe
              src={data.sourceCode}
              className="margin-auto w-full h-screen"
              title="Iframe"
            />
          </div>
        </div>
      </div>
    )
  );
}
