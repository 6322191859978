import Modal from "react-modal"
import CloseButton from "shared/CloseButton"
import useWindowSize from "hooks/useWindowSize"
import { useQuotationPanel } from "../context"
import { ModalTypes } from "../types"
import { BUCKET_URL, LAYOUT_BREAKPOINT_WIDTH_MOBILE } from "const"

const PanelGuideSubtitlesImg = BUCKET_URL + "/assets/panel/panel-card-subtitles.webp"

function ModalPanelGuideSubtitles() {

  const { width } = useWindowSize()
  const { state, actions } = useQuotationPanel()
  const isOpen = state.modal?.type === ModalTypes.PANEL_GUIDE_SUBTITLES
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH_MOBILE

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => actions.closeModal()}
      style={{
        content: {
          margin: "auto",
          marginRight: isMobile ? -40 : "auto",
          marginLeft: isMobile ? -40 : "auto",
        }
      }}>
      <CloseButton onClick={() => actions.closeModal()} />
      <div className="relative flex flex-col h-full justify-between">
        <div className="mb-5">
          <h2 className="font-bold text-xl text-primary uppercase mb-2">Anatomia da Linha de Preços</h2>
        </div>

        <img src={PanelGuideSubtitlesImg} alt="Panel Guide Subtitles"/>

        <div className="text-gray-500 text-sm mt-4">
          * Para ativos como juros, juros futuro, renda fixa e debêntures,
          a cotação se refere à taxa/prêmio e não ao valor da unidade do ativo,
          portanto a variação pode estar negativa sem representar perda do valor do incial investimento
        </div>
      </div>
    </Modal>
  )
}

export default ModalPanelGuideSubtitles