import { Fragment, useState } from "react"
import Modal from "react-modal"
import CloseButton from "shared/CloseButton"
import useWindowSize from "hooks/useWindowSize"
import { LAYOUT_BREAKPOINT_WIDTH_MOBILE } from 'const'

export function ModalBenchmark({ isOpen = true, closeModal, states, selectList }) {

  const { width } = useWindowSize()
  const [selectedOption, setSelectedOption] = useState(states.benchmarkActive.name)
  const isMobile = width < LAYOUT_BREAKPOINT_WIDTH_MOBILE

  const handleChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const save = () => {
    selectList(selectedOption)
    closeModal()
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={{
      content: { 
        maxWidth: 413,
        maxHeight: 616,
        borderRadius: 12,
        margin: "auto",
        marginRight: isMobile ? -40 : "auto",
        marginLeft: isMobile ? -40 : "auto"
      }
    }}>
      <CloseButton className='absolute right-5 top-5 z-50' size={20} color={'#472F92'} onClick={closeModal} />
      <div className="relative flex flex-col justify-between h-full">
        <div>
          <div>
            <h2 className="font-bold text-xl text-primary uppercase mb-2">Editar Benchmark</h2>
            <p className='text-dark-gray text-base font-semibold'>Escolha o benchmark que você quer aplicar nessa lista.</p>
          </div>
          <div className="p-2">
            <ul className='flex flex-col'>
              {states.benchmarkListOptions.map((item) => {
                return (
                  <Fragment key={`Modal_Benchmark_${item}`}>
                    <li className='flex space-y-4 uppercase'>
                      <input className={'hidden'} type="radio" name="Modal_Benchmark" value={item} id={`Modal_Benchmark_ID_${item}`} onChange={handleChange} />
                      <label htmlFor={`Modal_Benchmark_ID_${item}`} className={`flex items-center space-x-4 text-sm text-dark-gray cursor-pointer`}>
                        <span className={`w-[25px] h-[25px] rounded-full text-sm text-dark-gray border border-primary cursor-pointer ${selectedOption === item ? 'bg-secondary border-[6px]' : ''}`}></span>
                        <span>{item}</span>
                      </label>
                    </li>
                  </Fragment>
                )
              })}
            </ul>
          </div>
        </div>

        <div className='flex justify-center'>
          <button className="btn btn-secondary m-2 text-primary" onClick={closeModal}>Cancelar</button>
          <button className="btn btn-primary m-2 text-secondary" onClick={() => save()}>Salvar</button>
        </div>
      </div>
    </Modal >
  )

}