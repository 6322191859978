import { forwardRef, useContext, createRef, useState, useEffect } from "react"
import { MdOutlineDiamond } from "react-icons/md"
import AccessInterceptorContext from "context/AccessInterceptorContext"
import useClickout from "hooks/useClickout"

function ContextualMenu({ btnTriggerClass = 'btn', children, id, automaticClose = false, ...props }) {

  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const dropdownRef = createRef()
  const dropdownTriggerRef = createRef()
  useClickout([dropdownTriggerRef, dropdownRef], clickout => {
    if (clickout) setDropdownOpen(false)
  })

  useEffect(() => {
    if (automaticClose) setDropdownOpen(false)
  }, [automaticClose])

  return (
    <button onBlur={() => setDropdownOpen(false)} onClick={() => setDropdownOpen(true)} ref={dropdownTriggerRef} className={btnTriggerClass} id={id}>
      {children}
      {isDropdownOpen && 
        <div onMouseLeave={() => setDropdownOpen(false)}>
          <ContextualMenuOptions {...props} ref={dropdownRef} />
        </div>
      }
    </button>
  )
}

const ContextualMenuOptions = forwardRef(({ options = [] }, ref) => {
  return (
    <div ref={ref} className="fixed bg-white shadow mt-2 right-6 rounded-sm text-danger border w-30" style={{ zIndex: 900 }}>
      {
        options.map(opt => opt.type === 'divider'
          ? <hr className="my-2" key={'ctx-menu-opt-' + opt.label} />
          : <ContextualMenuOption key={'ctx-menu-opt-' + opt.label} {...opt} />
        )
      }
    </div>
  )
})

const ContextualMenuOption = ({ label, onClick, blocked, disabled, styleClass }) => {
  const { setIsAccessBlocked } = useContext(AccessInterceptorContext)
  return (
    <div onClick={blocked || disabled ? blocked ? () => setIsAccessBlocked(true) : () => {} : onClick} className={`text-xs font-semibold py-2 px-2 flex justify-between  ${(blocked || disabled) ? 'text-grayDisabled cursor-not-allowed' : 'hover:bg-gray-100 cursor-pointer text-primary'} ${styleClass}`}>
      {label}
      {blocked && <MdOutlineDiamond className="text-terciary ml-1 inline" />}
    </div>
  )
}

export default ContextualMenu